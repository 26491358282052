.iframe-container {
  grid-column: 1 / -1;
  /* Last row contains footer */
  grid-row: 1 / -2;
  width: 100%;
  height: 100%;
  overflow: none;
}

.iframe-container iframe {
  height: 100%;
  width: 100%;
}
