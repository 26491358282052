.not-found-p{
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    .vector{}
    .meta{
        text-align: center;
        margin-top: 20px;
        .lbl{line-height: 1.4;} 
    }
}  