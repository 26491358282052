@value mobile from "./media.css";

.transition-all {
  transition: all 225ms ease;
}

.icon-scale-transform {
  cursor: pointer;
  justify-content: center;
  justify-self: center;
  text-align: center;
}

.icon-scale-transform[data-selected='true'],
.icon-scale-transform:hover {
  background: var(--button-background-dark);
}

.main-action-button {
  background: var(--button-background);
  cursor: pointer;
}

.hover-outline {
  border: none;
  outline: none;
}

.hover-outline[data-selected='true'],
.hover-outline:hover {
  box-shadow: var(--primary-color) 0px 0px 0px 2px;
}

.without-scrollbar {
  overflow-y: hidden !important;
}

.with-scrollbar {
  scrollbar-color: var(--primary-color) var(--button-background); /* Thumb, track */
}

.with-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.with-scrollbar::-webkit-scrollbar-track {
  background: var(--button-background);
  border-radius: 5px;
}

.with-scrollbar::-webkit-scrollbar-thumb {
  background: var(--button-background);
  border: var(--primary-color) 2px solid;
  border-radius: 5px;
}

.with-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

@media mobile {
  .with-scrollbar::-webkit-scrollbar {
    width: 15px;
  }
}
