.c-slide-btn-block {
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: 'orange';
}

.c-slide-btn-frame {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 1000px;
  background-color: rgba(255, 255, 255, 0.151);
}

.play-btn {
  z-index: 50;
  width: 25px;
  height: 25px;
  -webkit-transform: translate(3px, 0px);
  -ms-transform: translate(3px, 0px);
  transform: translate(3px, 0px);
}

.c-slide-btn-loading {
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  border-style: dashed;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 1000px;
  background-color: rgba(34, 34, 34, 00);
}

.c-play-btn {
  background-color: 'orange';
  width: '100%';
  height: '100%';
}
.c-play-btn::hover {
  animation: spin 1s linear infinite;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes no-spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
