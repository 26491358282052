.share_count {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: white;
}

.share_icon {
  width: 48px;
  height: 48px;
  background-color: #00bfff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  cursor: pointer;
}

.share_actions {
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
}

.share_actions div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
  padding: 20px;
}

.share_actions div:hover {
  background-color: #00bfff;
  cursor: pointer;
}

.share_actions div svg {
  margin-bottom: 5px;
}

.share_popup_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.share_popup_container {
  background-color: white;
  padding: 25px 25px 50px;
  border-radius: 10px;
  width: 400px;
  position: relative;
}

.share_popup_header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.share_popup_title {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 50px 0 20px;
}

.share_popup_dropdown {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 10px 0 15px;
}

.share_popup_next {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  width: 30%;
  float: right;
  margin-top: 50px;
  color: white;
  border-radius: 10px;
  background: #8498a4;
  cursor: pointer;
}
