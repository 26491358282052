@import './Colors.scss';
@import './Props.scss';
@import './media.scss';
@import './uifonts.scss';

* {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
}

.body {
    font-family: 'Inter', sans-serif !important;
    scroll-behavior: smooth;

    @include media('>=480px') {
        overflow: auto !important;
    }
}

.hideme {
    display: none;
}

.MuiTab-root {
    font-family: 'Inter', sans-serif !important;
}

.slick-slider {
    width: 100%;

    .slick-prev,
    .slick-next {}
}

.app-loader {
    justify-content: center;
    position: absolute;
    inset: 0;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.8);
}

.hide-element {
    position: fixed;
    top: -9999975px;
    left: -98787576px;
}

.splash {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .img {
        height: 3.2rem;
    }

    .logo-txt {
        margin-left: -35px;
        z-index: 2;
    }
}

.font {
    font-family: 'Inter', sans-serif;
}

.fontr {
    font-family: 'Varela Round', sans-serif;
}

$font: 'Inter',
sans-serif;

.disable {
    &:hover {
        cursor: no-drop;
    }
}

.wrapWidth {
    width: 1200px;
    margin: 0 auto;

    @include media('<=large') {
        width: 100%;
        padding: 0px 22px;
    }

    @include media('<=480px') {
        padding: 0px 20px;
    }
}

@mixin imageBefore($radius: 50%) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: $radius;
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

@mixin bgImage($radius: 50%) {
    height: 100%;
    width: 100%;
    border-radius: $radius;
    background-color: #e2e2e2;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

@mixin hide {
    opacity: 0;
    visibility: hidden;
}

@mixin show {
    opacity: 1;
    visibility: visible;
}

@mixin dropDownAnim($duration: 0.3s) {
    animation-name: drop;
    animation-duration: $duration;
    transform-origin: top;
}

@keyframes drop {
    0% {
        transform: scaleY(0.5);
        opacity: 0;
    }
}

// Pagination
.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .pagination-btn {
        height: 33px;
        width: 33px;
        min-width: 33px;
        border-radius: 50%;
        font-size: 15px;
        background: transparent;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include anim(0.1s);

        &.active {
            background: $themeColor;
            color: #fff;
        }

        &:disabled {
            opacity: 0.6;
            cursor: no-drop;
        }
    }

    .pagination-links {
        display: flex;
        align-items: center;
        margin: 0 12px;
    }

    .pagination-item {
        outline: none;
        border: none;
        background: transparent;
    }
}

.App {
    // background: #f5f7fb;
    background: transparent;
    min-height: 100vh;
}

// signup-billing
.signup-billing {
    border: 1px solid #ddd;
    padding: 25px;

    .billing-form {
        .meta {
            .slog {
                margin-bottom: 3px;
            }

            .tit {
                line-height: 1;
            }

            .msg {
                margin: 16px 0px;
            }
        }

        .confirm-btn {
            width: 100%;
            background: $themeColor;
            padding: 9px;
            margin: 25px 0px 22px 0px;
            border-radius: 3px;

            &:hover {
                opacity: 0.8;
            }
        }

        .txt {
            text-align: center;
        }
    }
}

$sidebarWidth: 70px;
$subMenuSidebar: 180px;
$marginLeftSpace: $subMenuSidebar+$sidebarWidth;
$horizontalPending: 20px;
$smallScreenHorizontalPending: 22px;

@include media('<=480px') {
    $smallScreenHorizontalPending: 20px;
}

.sidebar-space {
    margin-left: $sidebarWidth;

    @include media('<=desktop') {
        margin-left: 0px;
    }

    &.sidebar-with-sub-menu {
        margin-left: $marginLeftSpace;

        @include media('<=desktop') {
            margin-left: 0px;
        }
    }
}

.sidebar-overlay {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.38);
    z-index: 999;

    @include media('<=desktop') {
        display: flex;
    }
}

// App Sidebar
.app-sidebar {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    width: $sidebarWidth;
    background: #fff;
    border-right: 1px solid $borderColor;
    z-index: 999;

    @include media('<=desktop') {
        z-index: 1001;
        @include hide;
        left: -$sidebarWidth;

        &.open {
            @include show;
            left: 0;
        }
    }

    .wrap {
        padding: 17px 0px;
        height: 100%;

        .sidebar-logo {
            height: 32px;
        }

        .sidebar-tooltip {
            position: absolute;
            left: 39px;
            background: #121212;
            color: #fff;
            font-size: 13px;
            padding: 4px 9px;
            border-radius: 3px;
            min-width: max-content;
            @include anim;
            @include hide;
        }

        .nav {
            margin-top: 60px;
            flex: 1;

            .lin {
                margin-bottom: 16px;
                cursor: pointer;

                &.display {
                    display: flex;
                }

                &.hidden {
                    display: none;
                }

                .ico {
                    height: 38px;
                    width: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    background: transparent;
                    @include anim;

                    span {
                        color: #446888;
                        @include anim;
                    }

                    svg {
                        height: 19px;
                        width: 19px;
                        @include anim;

                        path {
                            @include anim;
                        }
                    }

                    &.Messenger {
                        svg {
                            height: 22px;
                            width: 22px;
                        }
                    }
                }

                &.active,
                &:hover {
                    .ico {
                        background: #ebf5ff;

                        svg {
                            path {
                                fill: $themeColor;
                            }
                        }

                        span {
                            color: $themeColor;
                        }
                    }
                }

                &:hover {
                    .sidebar-tooltip {
                        @include show;
                    }
                }
            }
        }

        .user {
            cursor: pointer;

            .user-dp {
                @include bgImage;
                height: 37px;
                width: 37px;
            }

            .sidebar-tooltip {
                top: 5px;
                left: 42px;
            }

            &:hover {
                .sidebar-tooltip {
                    @include show;
                }
            }
        }
    }
}

// Min Sidebar for small screen
.min-screen-sidebar {
    top: 0px;
    left: 0px;
    right: 0px;
    height: 58px;
    position: sticky;
    background: #fff;
    border-bottom: 1px solid $borderColor;
    z-index: 990;
    justify-content: space-between;
    display: none;

    @include media('<=desktop') {
        display: flex;
    }

    .wrap {
        width: 100%;
        padding: 0px $smallScreenHorizontalPending;

        .left {
            flex: 1;

            .sidebar-logo {
                height: 32px;
            }
        }

        .right {
            flex: 1;
            justify-content: flex-end;
        }
    }
}

// sub menu sidebar
.sub-menu-sidebar {
    position: fixed;
    top: 0px;
    left: $sidebarWidth;
    bottom: 0;
    width: $subMenuSidebar;
    background: #fff;
    border-right: 1px solid $borderColor;
    z-index: 900;

    &.hid {
        opacity: 0 !important;
        visibility: hidden !important;
    }

    @include media('<=desktop') {
        z-index: 1000;

        &.open {
            @include show;
        }

        &.close {
            left: -$marginLeftSpace;
        }
    }

    .wrap {
        padding: 17px 8px;
        height: 100%;

        .nav {
            width: 100%;

            .lin {
                width: 100%;
                padding: 6px 20px;
                border-radius: 4px;

                &:not(:last-child) {
                    margin-bottom: 14px;
                }

                .ico {
                    margin-right: 5px;

                    svg {
                        height: 18px;
                        width: 18px;
                        @include anim;

                        path {
                            @include anim;
                        }
                    }
                }

                .lbl {
                    font-size: 14px;
                    font-weight: 400;
                    color: #446888;
                }

                &:first-child,
                .active {
                    background: #f5faff;

                    .ico {
                        svg {
                            path {
                                fill: $themeColor;
                            }
                        }
                    }

                    .lbl {
                        color: $themeColor;
                    }
                }
            }
        }
    }
}

// In Page Header
.in-page-header {
    top: 0px;
    right: 0px;
    height: 62px;
    position: sticky;
    background: #fff;
    border-bottom: 1px solid $borderColor;
    z-index: 999;

    @include media('<=desktop') {
        position: inherit;
        background: transparent;
        border: none;
        padding-top: 22px;
        height: inherit;
    }
}

// Header
.navbar-menu {
    position: sticky !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 990;

    .main-hdr {
        border-bottom: 1px solid #dddddd;
        padding: 14px 0px;

        .lit {
            flex: 1;

            .hdr-logo {
                height: 33px;
            }
        }

        .rit {
            flex: 1;
            justify-content: flex-end;

            .user-dp {
                @include bgImage;
                height: 32px;
                width: 32px;
                margin-right: 8px;
            }

            .user-bl {
                cursor: pointer;

                .meta {
                    .nam {
                        line-height: 1.1;
                    }
                }

                .menu {
                    .options {
                        width: 185px;
                        border: 1px solid #c4c4c4;
                        background: #fff;
                        padding: 5px 0px 5px 0px;
                        border-radius: 3px;
                        top: 30px;
                        right: 0px;
                        @include hide;

                        &.drop {
                            @include dropDownAnim;
                            @include show;
                        }

                        &::before {
                            content: '';
                            height: 9px;
                            width: 9px;
                            background: #fff;
                            border: 1px solid transparent;
                            border-top-color: #c4c4c4;
                            border-left-color: #c4c4c4;
                            position: absolute;
                            right: 14px;
                            top: -5px;
                            transform: rotate(45deg);
                        }

                        .lin {
                            padding: 8px 16px;

                            &:hover {
                                background: #f7f7f7;
                            }

                            .raw {
                                width: 100%;

                                .item {
                                    flex: 1;

                                    .ico {
                                        margin-right: 7px;
                                    }
                                }

                                .tag {
                                    background: $themeColor;
                                    padding: 3px 5px;
                                    border-radius: 3px;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sub-header {
        border-bottom: 1px solid #c4c4c4;

        .item {
            padding: 12px 0px;
            margin-right: 30px;

            &:last-child {
                margin-right: 0px;
            }

            .ico {
                margin-right: 7px;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -1px;
                display: block;
                width: 0;
                height: 1.5px;
                background: $themeColor;
                transition: width 0.3s;
            }

            &.active {
                .ico {
                    color: $themeColor;
                }

                .lbl {
                    color: $themeColor;
                }

                &::after {
                    width: 100%;
                }
            }

            &:hover {
                color: $themeColor;

                &::after {
                    width: 100%;
                }
            }
        }
    }
}

// Signup Personal Info Section
.signup-personal-info {
    .hdr {
        .meta {
            .step {
                margin-bottom: 12px;
            }

            .title {
                line-height: 1.5;
            }

            .text {}
        }
    }

    .form {
        margin-top: 22px;

        .iput {
            font-size: 14px;
            color: $black;
            padding: 10px 20px;
            // border: 1px solid $border-color-1;
            background: rgb(243, 243, 244);
            border-radius: 3px;
            @include anim;
            height: 45px;

            &:focus {
                border-color: $lightBlack;
                background: transparent;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        .next-btn {
            font-size: 14px;
            font-weight: 500;
            color: $white;
            background: $themeColor;
            width: 150px;
            height: 42px;
        }
    }
}

.dashboard-hdr {
    padding: 0px $horizontalPending;
    width: 100%;

    @include media('<=large') {
        padding: 0px;
    }

    .left {
        flex: 1;

        .item {
            .ico {
                margin-right: 8px;
            }

            .slash {
                color: #8c8c8c;
            }

            .txt {
                @include media('<=480px') {
                    font-size: 13px;
                }
            }

            &:first-child {
                .txt {
                    color: #8c8c8c;
                }
            }

            &:hover {

                .ico,
                .txt {
                    color: $themeColor;
                }
            }
        }
    }

    .right {
        flex: 1;
        justify-content: flex-end;

        .add-category {
            background: $themeColor;
            padding: 12px 14px;
            line-height: 1;
            border-radius: 3px;
            color: #fff;
            text-align: center;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.13), 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            @include anim;

            .ico {
                margin-right: 5px;
                line-height: 1;
                color: #fff;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:hover {
                box-shadow: none;
                opacity: 0.9;
            }

            @include media('<=480px') {
                padding: 12px 11px;
                font-size: 13px;

                .ico {
                    font-size: 14px;
                }
            }
        }

        .embed-code {
            background: #fff;
            padding: 12px 14px;
            line-height: 1;
            border-radius: 3px;
            color: $themeColor;
            font-weight: bold;
            text-align: center;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.13), 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            @include anim;

            .ico {
                margin-right: 5px;
                line-height: 1;
                color: #fff;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:hover {
                box-shadow: none;
                opacity: 0.9;
            }

            @include media('<=480px') {
                padding: 12px 11px;
                font-size: 13px;

                .ico {
                    font-size: 14px;
                }
            }
        }
    }
}

// Manage Communities page
.manage-community {
    .community-hdr {
        padding: 0px 20px;
        width: 100%;
        justify-content: space-between;

        @include media('<=tablet') {
            flex-direction: column;
            align-items: flex-start;
        }

        .left {
            flex: 1;

            @include media('<=tablet') {
                margin-bottom: 10px;
            }

            @include media('<=phoneH') {
                width: 100%;
                justify-content: space-between;
            }

            .title {
                &.holder {
                    height: 20px;
                    width: 50%;
                }

                @include media('<=480px') {
                    font-size: 16px;
                }
            }

            .add-category {
                display: none;

                @include media('<=phoneH') {
                    font-size: 13px;
                    padding: 13px 10px;
                    display: flex;

                    .ico {
                        font-size: 13px;
                    }
                }
            }
        }

        .right {
            flex: 2;
            justify-content: space-between;

            @include media('<=desktop') {
                flex: 2.5;
            }

            @include media('<=tablet') {
                width: 100%;
            }

            .srch-bar {
                width: 360px;
                background: #f0f2f5;
                border: 1px solid #f0f2f5;
                border-radius: 4px;
                overflow: hidden;

                @include media('<=tablet') {
                    width: auto;
                    flex: 1;
                }

                .iput {
                    width: 100%;
                    padding: 9px 20px;
                }
            }

            .add-category {
                @include media('<=tablet') {
                    margin-left: 10px;
                }

                @include media('<=phoneH') {
                    display: none;
                }
            }
        }

        .add-category {
            background: $themeColor;
            padding: 13px 14px;
            line-height: 1;
            border-radius: 7px;
            color: #fff;
            text-align: center;

            @include media('<=phoneH') {
                padding: 14px 12px;
            }

            .ico {
                margin-right: 5px;
                line-height: 1;
                color: #fff;

                @include media('<=phoneH') {
                    font-size: 15px;
                }
            }
        }
    }

    .communities-wrapper {
        padding-top: 30px;
        padding-bottom: 30px;

        .community-list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 25px;
            padding-bottom: 30px;

            @include media('<=desktop') {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media('<=large') {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media('<=phoneH') {
                grid-template-columns: repeat(1, 1fr);
            }

            .holder {
                height: 317px;
                border-radius: 0px;
                width: 100%;
                min-width: 100%;
            }

            .cumunity-card {
                background: #fff;
                width: 100%;
                min-width: 100%;

                .graphic {
                    height: 200px;
                    width: 100%;
                    overflow: hidden;

                    @include media('<=480px') {
                        height: 220px;
                    }

                    .img {
                        @include bgImage(0px);
                        @include anim;
                    }
                }

                .graphic .graphic_hover {
                    opacity: 0;
                    transition: .3s linear all;
                }

                .graphic .graphic_hover button {
                    margin-top: 20px;
                    opacity: 0;
                }
                .graphic .graphic_hover:hover button {
                    margin-top: 0px;
                    opacity: 1;
                }
                .graphic:hover .graphic_hover {
                    opacity: 1;
                }

                .meta {
                    align-items: flex-start;
                    padding: 14px 14px;
                    border-bottom: 1px solid $borderColor;

                    .lbl {
                        line-height: 1.4;
                    }

                    .url {
                        color: #767676;
                    }
                }

                .ftr {
                    justify-content: space-between;
                    padding: 9px 14px;

                    .ftr-btn {
                        background: rgba(0, 0, 0, 0.05);
                        padding: 4px 12px;
                        border-radius: 60px;

                        &:hover {
                            background: $themeColor;
                            color: #fff;
                        }
                    }
                }

                &:hover {
                    .graphic {
                        .img {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }

    .empty-comunity {
        min-height: 64vh;
        justify-content: center;

        .msg {
            margin-bottom: 14px;
        }

        .add-new-comunity {
            background: #121212;
            padding: 10px 20px;

            &:hover {
                background-color: $themeColor;
                color: #fff;
            }
        }
    }
}

// apart intro slider
.apart-intro-slider {
    padding: 25px $horizontalPending;
    overflow: hidden;

    @include media('<=large') {
        padding: 25px 0px;
    }

    .intro-cards {
        width: 100%;
        justify-content: space-between;

        .slide-blk {
            width: 100%;
        }

        .intro-card {
            background: rgba(0, 0, 0, 0.02);
            width: 97%;
            height: 120px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            overflow: hidden;
            cursor: pointer;
            @include anim;

            &.step-1 {
                .vector {
                    .graphic {
                        bottom: -5px;
                    }
                }
            }

            &.step-3 {
                .vector {
                    .graphic {
                        height: 116px;
                        right: 2px;
                        right: 5px;
                    }
                }
            }

            &.step-4 {
                .vector {
                    .graphic {
                        height: 82px;
                    }
                }
            }

            &:not(:last-child) {
                margin-right: 22px;
            }

            .meta {
                flex: 1.2;
                padding: 12px 14px;

                .tit {
                    margin-bottom: 4px;
                    color: $black;
                }

                .txt {
                    line-height: 1.3;
                    font-size: 13px;
                }
            }

            .vector {
                flex: 1;
                position: relative;

                .graphic {
                    height: 100px;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    z-index: 2;
                }

                .lines {
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    @include anim;

                    .line {
                        height: 13px;
                        width: 100%;
                        background: #e4e4e4;
                        @include anim;

                        &:not(:last-child) {
                            margin-bottom: 7px;
                        }

                        &.large {
                            height: 45px;

                            .send-icon {
                                position: absolute;
                                left: -8px;
                                top: 0px;

                                svg {
                                    height: 18px;
                                    width: 18px;
                                    @include anim;
                                }
                            }
                        }
                    }
                }

                .cap-icon {
                    position: absolute;
                    left: 25px;
                    top: -2px;

                    svg {
                        height: 40px;
                        width: 40px;
                        @include anim;
                    }
                }

                .camera-light-top {
                    position: absolute;
                    right: -8px;
                    bottom: 52px;

                    svg {
                        @include hide;
                        @include anim;
                    }
                }

                .camera-light-bottom {
                    position: absolute;
                    right: -2px;
                    bottom: 16px;

                    svg {
                        @include hide;
                        @include anim;
                    }
                }

                .off {
                    background: #fff;
                    border: 1px solid #d3d3d3;
                    padding: 0px 6px;
                    width: fit-content;
                    border-radius: 3px;
                    position: absolute;
                    right: 7px;
                    top: 5px;
                    line-height: 1;
                    padding: 3px 8px;

                    .icon {
                        margin-right: 3px;

                        svg {
                            height: 7px;
                            width: 8px;

                            path {
                                @include anim;
                            }
                        }
                    }
                }
            }

            &:nth-child(1) {
                bottom: -4px;
                right: -1px;
            }

            &:hover {
                background: rgba(56, 152, 236, 0.1);
                border-color: $themeColor;

                .lines {
                    .line {
                        background: rgba(56, 152, 236, 0.4);

                        svg {
                            path {
                                stroke: $themeColor;
                            }
                        }
                    }
                }

                .cap-icon {
                    svg {
                        path {
                            fill: $themeColor;
                        }
                    }
                }

                .camera-light-top,
                .camera-light-bottom {
                    svg {
                        @include show;
                    }
                }
            }
        }
    }
}

// Apartment View Page
.apartment-page {
    .apartment-nav {
        width: 100%;

        .lin {
            width: 100%;
            padding: 6px 20px;
            border-radius: 4px;

            &:not(:last-child) {
                margin-bottom: 14px;
            }

            .ico {
                margin-right: 5px;

                svg {
                    height: 18px;
                    width: 18px;
                    @include anim;

                    path {
                        @include anim;
                    }
                }

                span {
                    color: #446888;
                    font-size: 19px;
                }
            }

            .lbl {
                font-size: 14px;
                font-weight: 400;
                color: #446888;
            }

            &.active {
                background: #f5faff;

                .ico {
                    svg {

                        path,
                        stroke {
                            fill: $themeColor;
                        }
                    }

                    span {
                        color: $themeColor;
                    }
                }

                .lbl {
                    color: $themeColor;
                }
            }
        }
    }

    .apart-container {
        background: #fff;
        position: relative;
        width: 100%;

        .apart-content {}

        .magnnet-container {
            padding: 0px !important;
        }
    }

    .empty-div {
        height: 40px;
    }

    @keyframes slide-in-bottom {
        0% {
            transform: translateY(1000px);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .simple-leads-title {
        padding: 17px 0px;
        margin-right: 20px;
    }

    .upload-toast {
        left: 30px;
        background: #fff;
        z-index: 1000;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        padding: 10px;
        min-width: 397px;
        max-width: 397px;
        bottom: 30px;
        z-index: 9999;

        &.sho {
            opacity: 1;
            visibility: visible;
            animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        &.hid {
            opacity: 0;
            visibility: hidden;
        }

        .lef-blk {
            background: $themeColor;
            height: 85px;
            width: 85px;
            border-radius: 5px;
            justify-content: center;
            text-align: center;

            .no {
                line-height: 1.3;
                font-size: 24px;
            }

            .lbl {
                letter-spacing: 0.3px;
            }
        }

        .rit-blk {
            margin-left: 12px;

            .meta {
                margin-bottom: 2px;

                .nam {
                    flex: 1;
                    margin-right: 10px;
                }

                .size {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .prograss-bar {
                width: 280px;
                background: #e6efff;
                height: 4px;

                .prograss {
                    top: 0;
                    bottom: 0;
                    background: $themeColor;
                }
            }

            .ftr {
                margin-top: 10px;

                .nam {
                    flex: 1;
                    margin-right: 10px;
                }

                .more {
                    cursor: pointer;

                    .more-list {
                        background: rgba(0, 0, 0, 1);
                        width: 200px;
                        padding: 8px 14px;
                        bottom: 40px;
                        left: -70px;
                        border-radius: 3px;
                        opacity: 0;
                        visibility: hidden;
                        z-index: 5;

                        &::after {
                            content: '';
                            height: 21px;
                            width: 23px;
                            background: #000;
                            position: absolute;
                            right: 90px;
                            bottom: -1px;
                            z-index: -1;
                            transform: rotate(45deg);
                        }

                        .itm {
                            margin-bottom: 2px;
                            font-size: 13px;
                        }
                    }

                    &:hover {
                        .more-list {
                            bottom: 30px;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .badge {
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 3px;
        background-color: #868e96;

        &.info {
            background: #17a2b8 !important;
        }

        &.danger {
            background: #fa4654 !important;
        }
    }
}

// Add Category Button
.add-category-dialog {
    width: 100%;

    .hdr {
        padding: 8px 20px;
        border-bottom: 1px solid #ddd;
        height: 50px;

        .title {
            flex: 1;
            color: $black;
        }

        .cross-btn {
            font-size: 28px;
            color: $black;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .wrap {
        margin: 20px 20px;

        .iput {
            width: 100%;
            border: 1px solid #ddd;
            padding: 11px 20px;
            border-radius: 3px;

            &:focus {
                border-color: $themeColor;
            }
        }
    }

    .ftr {
        padding: 0px 20px 20px 20px;

        .add-cata {
            background: $themeColor;
            width: 100%;
            padding: 10px 20px;
            border-radius: 3px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.cancel {
                background: transparent;
                border: 1px solid #ddd;
            }
        }
    }
}

// Delete Screen
.add-category-dialog {
    width: 100%;

    .hdr {
        padding: 8px 20px;
        border-bottom: 1px solid #ddd;
        height: 50px;

        .title {
            flex: 1;
            color: $black;
        }

        .cross-btn {
            font-size: 28px;
            color: $black;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .wrap {
        margin: 20px 20px;

        .txt {}
    }

    .ftr {
        padding: 0px 20px 20px 20px;

        .add-cata {
            background: $themeColor;
            width: 100%;
            padding: 10px 20px;
            border-radius: 3px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.cancel {
                background: transparent;
                border: 1px solid #ddd;
            }
        }
    }
}

.google-sheet-btn {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
    padding: 6px 14px;
    border-radius: 4px;
    margin-left: 20px;
    @include anim;

    &:hover {
        background: rgba(0, 0, 0, 0.025);
    }

    .ico {
        color: #238a23;
        margin-right: 5px;
    }
}

.advanced-upload-btns {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 15px;

    .google-sheet-btn {
        padding: 10px 22px;

        .img {
            height: 22px;
            margin-right: 10px;
        }
    }

    .record-new-video-btn {
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
        padding: 12px 14px;
        border-radius: 4px;

        .record-icon {
            width: 15px;
            height: 15px;
            background-color: rgb(240, 21, 21);
            border-radius: 100%;
            margin-right: 10px;
            min-width: 15px;
        }

        .grey-record-icon {
            width: 15px;
            height: 15px;
            background-color: rgba(0, 0, 0, 0.18);
            border-radius: 100%;
            margin-right: 10px;
            min-width: 15px;
        }

        @include anim;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }

        .ico {
            color: #238a23;
            margin-right: 5px;
        }

        .img {
            height: 22px;
            margin-right: 10px;
        }
    }
}

.upload-import-vid-btn {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
    padding: 12px 14px;
    border-radius: 4px;
    margin-top: 20px;
}

// Magnet Magnet Page
.magnnet-container {
    width: 100%;
    padding: 2.3rem;
}

// Magnet Summary && Magnet Item
.magnet-sumary {
    .summary-hdr {
        padding: 0px $horizontalPending;

        @include media('<=large') {
            padding: 0px 0px;
        }

        .left {
            flex: 1;

            .video-search-bar {
                width: 400px;
                background: rgba(0, 0, 0, 0.02);
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                overflow: hidden;

                @include media('<=phoneH') {
                    width: 100%;
                    margin-right: 12px;
                }

                .iput {
                    width: 100%;
                    padding: 10px 20px;

                    @include media('<=480px') {
                        font-size: 13px;
                        padding: 9px 12px;
                    }
                }
            }
        }

        .right {
            flex: 1;
            justify-content: flex-end;

            @include media('<=phoneH') {
                flex: inherit;
            }

            .see-temp-btn {
                border: 1px solid $themeColor;
                padding: 12px 12px;
                line-height: 1;
                border-radius: 3px;
                color: $themeColor;
                text-align: center;
                @include anim;

                &:hover {
                    background: $themeColor;
                    color: #fff;
                }

                @include media('<=480px') {
                    font-size: 13px;
                    padding: 14px 10px;
                }

                &.sho {
                    display: flex;
                }

                &.hid {
                    display: none;
                }

                .ico {
                    margin-right: 3px;
                }
            }
        }
    }

    .sumary-content {
        position: relative;
        width: 100%;
        word-wrap: break-word;
        background-color: #fff;
        border-radius: 3px;

        .content-body {
            flex: 1 1 auto;
            margin: 0;
            position: relative;
            padding: 0px 4px;

            @include media('<=480px') {
                padding: 0px;
            }

            .del-cata-lbl {
                margin-bottom: 12px;
            }

            .del-acitons {
                width: 100%;

                .del-btn {
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    .tital {
        width: 100%;
        text-align: center;
        padding: 12px 0px 32px 0px;

        &.loading {
            justify-content: center;
        }

        .holder {
            width: 200px;
            height: 18px;
        }
    }

    .searchbar {
        &.holder {
            width: 100%;
            height: 54px;
        }
    }

    .MuiOutlinedInput-root {
        width: '100%';
        font-family: $font;
        color: $black;
    }

    .sumary-body {
        padding-bottom: 10px;

        &.holders {
            padding: 10px 20px;

            @include media('<=large') {
                padding: 10px 0px;
            }
        }

        .row-title {
            &.holder {
                width: 140px;
                margin: 20px 0px;
                padding: 9px 0px;
            }
        }

        .sumary-holders-wrapper {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-column-gap: 22px;
            grid-row-gap: 22px;

            &:last-child {
                padding-bottom: 18px;
            }

            @include media('<=large') {
                grid-template-columns: repeat(6, 1fr);
                grid-column-gap: 18px;
                grid-row-gap: 18px;
            }

            @include media('<=980px') {
                grid-template-columns: repeat(5, 1fr);
            }

            @include media('<=tablet') {
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 25px;
                grid-row-gap: 25px;
            }

            @include media('<=phoneH') {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media('<=480px') {
                grid-template-columns: repeat(2, 1fr);
            }

            .holder {
                @include media('<=large') {
                    &:nth-child(7) {
                        display: none;
                    }
                }

                @include media('<=980px') {
                    &:nth-child(6) {
                        display: none;
                    }
                }

                @include media('<=tablet') {
                    &:nth-child(5) {
                        display: none;
                    }
                }

                @include media('<=phoneH') {
                    &:nth-child(4) {
                        display: none;
                    }
                }

                @include media('<=480px') {
                    &:nth-child(3) {
                        display: none;
                    }
                }
            }
        }

        .magnet-item {
            &.holder {
                height: 140px;
                border-radius: 5px;
            }
        }

        figure {
            margin: 0px !important;
        }
    }

    .sumary-row {
        padding: 20px 0px;

        &:last-child {
            .divider {
                display: none;
            }
        }

        .divider {
            height: 1px;
            flex: 1;
            background: $borderColor;
            margin: 0px 20px;
        }

        .section-title {
            display: flex;
            align-items: center;
            padding: 0px 20px 0px 20px;
            font-size: 18px;
            color: #121212;
            line-height: 1;

            @include media('<=large') {
                padding: 0px 20px 0px 0px;
            }

            .title-icon {
                margin-left: 8px;
                cursor: pointer;
                height: 18px;
                width: 18px;
                color: #121212;
                transition: all 0.3s linear;
            }

            &:hover {
                .title-icon {
                    transform: rotate(120deg);
                }
            }
        }

        .row-items-block {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-column-gap: 3px;
            grid-row-gap: 0px;
            padding: 0px 9px;

            @include media('<=large') {
                grid-template-columns: repeat(6, 1fr);
                padding: 0px 0px;
                grid-column-gap: 1px;
            }

            @include media('<=980px') {
                grid-template-columns: repeat(5, 1fr);
            }

            @include media('<=tablet') {
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 8px;
            }

            @include media('<=phoneH') {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media('<=480px') {
                grid-template-columns: repeat(2, 1fr);
            }

            figure {
                margin: 0px;
            }
        }

        .sumary-row-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding: 20px 14px;
            min-width: 100%;

            .puse-ico {
                height: 21px;
                z-index: -1;
                top: 65px;
            }

            .item {
                height: 135px;
                width: 100%;
                justify-content: center;
                background: #f7f7f7;
                position: relative;
                z-index: 2;
                border-radius: 8px;
                @include anim(0.15s);

                @include media('<=480px') {
                    height: 145px;
                }

                .item-video {
                    flex-shrink: 0;
                    height: 135px;
                    min-width: 135px;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    transition: all 0.2s ease-in-out;
                    object-fit: cover;
                    z-index: 9;
                    border-radius: 8px;
                    border-color: none;
                    overflow: hidden;
                    border: transparent;

                    @include media('<=480px') {
                        height: 145px;
                    }
                }

                .ico {
                    height: 22px;
                }

                .item-checkbox {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    width: 20px;
                    height: 20px;
                    outline: none;
                    cursor: pointer;
                    background: #e7eefd;
                    border: 1px solid $borderColor;
                    border-radius: 3px;
                    @include anim;

                    @include media('<=480px') {
                        height: 18px;
                        width: 18px;
                        min-width: 18px;
                        top: auto;
                        bottom: -27px;
                        left: 3px;
                        position: absolute;
                        @include show;
                    }
                }

                &:hover {
                    .item-checkbox {
                        @include show();
                    }
                }
            }

            .ftr {
                margin-top: 8px;
                width: 100%;
                text-align: center;
                justify-content: center;
                align-items: flex-start;

                .lbl {
                    font-size: 15px;
                    font-weight: 300;
                    color: $black;
                    flex: 1;
                    text-align: center;
                    line-height: 1.3;

                    @include media('<=480px') {
                        font-size: 14px;
                        margin-right: 0px;
                        margin-left: 22px;
                    }
                }

                .moveScreenHandle {
                    left: -5px;
                    top: -2px;
                    @include anim;
                    opacity: 0;
                    visibility: hidden;

                    .drag-ico {
                        height: 26px;

                        @include media('<=480px') {
                            display: none;
                        }
                    }
                }

                .link-btn {
                    @include media('>480px') {
                        display: none;
                    }
                }
            }

            .views {
                font-size: 13px;
                font-weight: 300;
                color: $lightBlack;
                display: flex;
                align-items: center;

                .ico {
                    font-size: 13px;
                    margin-right: 4px;
                }
            }

            .links-btns {
                position: absolute;
                top: 194px;
                left: 24px;
                right: 24px;
                z-index: 3;
                @include hide;
                @include anim;
                @include dropDownAnim;

                @include media('<=480px') {
                    &.sho {
                        visibility: visible !important;
                        opacity: 1 !important;
                    }

                    &.hid {
                        visibility: hidden !important;
                        opacity: 0 !important;
                    }
                }

                .link {
                    background: rgba(0, 0, 0, 0.9);
                    width: 100%;
                    padding: 0px 10px;
                    border-radius: 4px;
                    margin-bottom: 4px;
                    @include anim(0.2s);

                    &:hover {
                        background: $themeColor;
                    }

                    .link-ico {
                        margin-right: 5px;
                        font-size: 13px;
                    }
                }
            }

            &:hover {
                .item {
                    filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.08));
                    transform: translateY(-4px);
                }

                .moveScreenHandle {
                    opacity: 1;
                    visibility: visible;
                }

                .links-btns {
                    opacity: 1;
                    visibility: visible;

                    @include media('<=480px') {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            &.disable {
                &:hover {
                    cursor: no-drop;

                    .item {
                        filter: inherit;
                        transform: translateY(0px);
                    }
                }
            }
        }

        .summary-row-edit-blk {
            padding: 0px $horizontalPending;
            margin-bottom: 32px;

            @include media('<=large') {
                padding: 0px;
            }

            .cancel-btn {
                margin-bottom: 22px;

                .ico {
                    margin-right: 5px;
                }
            }

            .edit-row-content {
                @include media('<=phoneH') {
                    align-items: flex-start;
                }

                .form {
                    flex: 1;

                    @include media('<=phoneH') {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .field {
                        width: 100%;

                        .MuiOutlinedInput-input {
                            border-top: 1px solid $borderColor;
                        }
                    }

                    .actions {
                        margin-left: 20px;

                        @include media('<=phoneH') {
                            margin-left: 0px;
                            margin-top: 20px;
                            width: 100%;
                        }

                        .action-btn {
                            display: flex;
                            align-items: center;
                            font-size: 15px;
                            color: $black;
                            background: #f1f5f8;
                            padding: 8px 20px;
                            border-radius: 5px;
                            margin-right: 10px;
                            font-weight: 400;

                            @include media('<=phoneH') {
                                width: 100%;
                                justify-content: center;
                                text-align: center;

                                &:last-child {
                                    margin-right: 0px;
                                }
                            }

                            &.update-btn {
                                background: $themeColor;
                                color: #fff;
                            }
                        }
                    }
                }

                .up-down-btns {}
            }

            &.delete {
                .edit-row-content {
                    .form {
                        .actions {
                            margin-left: 0px;

                            @include media('<=phoneH') {
                                flex-direction: column;
                                align-items: flex-start;
                                margin-top: 5px;

                                .action-btn {
                                    &:not(:last-child) {
                                        margin-bottom: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .share-footer-container {
        position: fixed;
        left: $marginLeftSpace;
        right: 0;
        padding: 12px $horizontalPending;
        display: flex;
        justify-content: center;
        height: 60px;
        background-color: #f8fafc;
        z-index: 90;
        border-top: 1px solid $borderColor;
        justify-content: space-between;
        transition: all 0.3s cubic-bezier(0.47, -0.12, 0.03, 1.11) 0s;

        @include media('<=desktop') {
            left: 0;
        }

        &.close {
            bottom: -80px;
            @include hide;
        }

        &.open {
            bottom: 0px;
            @include show;
        }

        .selected-videos {
            @include media('<=480px') {
                max-width: 100px;
            }

            .ico {
                margin-right: 6px;
                background: #0bc573;
                color: #fff;
                height: 28px;
                width: 28px;
                min-width: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                font-size: 15px;
            }
        }

        .action-btn {
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 40px;
            }

            .ico {
                color: $black;
                font-size: 19px;
                margin-bottom: 3px;
            }

            .lbl {
                color: $black;
                font-weight: 500;
            }

            &:hover {

                .ico,
                .lbl {
                    color: $themeColor;
                }
            }
        }

        .cross-btn {
            color: $black;
            font-size: 30px;
            font-weight: 300;
            background: #eff0f1;
            height: 38px;
            width: 38px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

// Template Preview Message Block
.temp-prev-msg {
    position: sticky;
    top: 0;
    left: 0;
    right: -1px;
    height: 50px;
    padding: 0px 40px;
    justify-content: center;
    background: #f5faff;
    z-index: 2;
    margin: 0px 20px 22px 20px;

    @include media('<=large') {
        margin: 0px 0px 22px 0px;
        padding: 0px 20px;
    }

    @include media('<=phoneH') {
        height: auto;
        padding: 15px 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    &.sho {
        display: flex;
    }

    &.hid {
        display: none;
    }

    .txt {
        margin-right: 40px;

        @include media('<=large') {
            font-size: 14px;
            margin-right: 18px;
            text-align: center;
            width: 100%;
        }
    }

    .actions {
        @include media('<=phoneH') {
            margin-top: 10px;
            width: 100%;
            justify-content: center;
        }

        .btn {
            border: 1px solid #c4c4c4;
            padding: 6px 16px;
            width: 120px;
            font-size: 15px;
            font-weight: 300;
            color: $black;
            text-transform: uppercase;
            @include anim;
            margin: 0px 10px;

            &.save-btn {
                border-color: $themeColor;
                color: $themeColor;
            }

            &:hover {
                background: $themeColor;
                color: #fff;
                border-color: $themeColor;
            }
        }
    }
}

// Select Template section
.see-temp-section {
    min-width: 100%;
    overflow: hidden;

    .hdr {
        flex: 1;
        padding: 14px 20px 14px 22px;
        border-bottom: 1px solid $borderColor;
        top: 0;
        right: 0;
        left: 0;

        .title {
            flex: 1;
        }

        .cross-btn {
            height: 30px;
            width: 30px;
            min-width: 30px;
            background: #f5f5f5;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            font-weight: 300;
            line-height: 1;
            padding-top: 2px;

            &:hover {
                background: $themeColor;
                color: #fff;
            }
        }
    }

    .temp-content {
        padding: 26px 22px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        overflow-y: auto;

        @include media('<=phoneH') {
            grid-template-columns: repeat(2, 1fr);
            height: 100%;
            max-height: 100%;
            overflow: auto;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        .temp-item {
            border: 1px solid $borderColor;
            width: 100%;
            border-radius: 3px;
            overflow: hidden;
            cursor: pointer;
            height: 150px;

            .media {
                width: 100%;
                height: 105px;

                .image {
                    @include bgImage(0px);
                }

                .overylay {
                    justify-content: center;
                    position: absolute;
                    inset: 0;

                    .txt {
                        @include hide();
                    }
                }
            }

            .meta {
                padding: 12px 10px;
                border-top: 1px solid $borderColor;
            }

            &:hover {
                opacity: 1;

                .overylay {
                    background: rgba(0, 0, 0, 0.3);

                    .txt {
                        @include show();
                    }
                }
            }
        }
    }
}

// Confirm Tempalte section
.confirm-temp-sec {
    width: 100%;
    overflow: hidden;

    .hdr {
        flex: 1;
        padding: 12px 20px 12px 22px;
        border-bottom: 1px solid #c4c4c4;
        top: 0;
        right: 0;
        left: 0;

        @include media('<=480px') {
            padding: 12px 17px 12px 14px;
        }

        .title {
            flex: 1;
        }

        .cross-btn {
            height: 30px;
            width: 30px;
            min-width: 30px;
            background: #f5f5f5;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                background: $themeColor;
                color: #fff;
            }
        }
    }

    .content {
        padding: 22px 22px;

        @include media('<=480px') {
            padding: 16px 14px;
        }
    }

    .actions {
        flex: 1;
        justify-content: flex-end;
        padding: 0px 22px 20px 22px;

        .btn {
            background: $themeColor;
            color: #fff;
            border: 1px solid $themeColor;
            margin-left: 12px;

            &:hover {
                opacity: 0.8;
            }

            &.cancel {
                background: transparent;
                border-color: $themeColor;
                color: $themeColor;

                &:hover {
                    background: $themeColor;
                    color: #fff;
                }
            }
        }
    }
}

// Upload Page
.upload-page {
    padding: 0px $horizontalPending;

    @include media('<=large') {
        padding: 0px 0px;
    }

    .upload-page-hdr {
        flex: 1;
        padding: 22px 0px 26px 0px;

        .left {
            flex: 1;

            .video-nam {
                color: $black;

                svg {
                    line-height: 1;
                    margin-bottom: 4px;
                    font-size: 20px;
                    margin-left: 2px;
                    color: $black;
                }
            }
        }

        .right {
            flex: 1;
            justify-content: flex-end;

            .back-btn {
                justify-content: center;

                .ico {
                    margin-right: 4px;
                    margin-top: 3px;
                }
            }
        }
    }
}

// upload page status bar
.bottom-status-bar {
    position: fixed;
    left: $marginLeftSpace;
    bottom: 0;
    right: 0;
    height: 60px;
    justify-content: center;
    border-top: 1px solid $borderColor;
    background-color: #f8fafc;
    padding: 15px $horizontalPending;
    z-index: 90;

    @include media('<=desktop') {
        left: 0px;
    }

    @include media('<=480px') {
        padding: 15px 11px;
        width: 100%;
        justify-content: space-around;
    }

    .status-bar-btn {
        outline: none;
        border: none;
        background: none;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 5px;
        padding: 8px 15px;
        font-weight: 500;

        @include media('<=480px') {
            padding: 8px 12px;
        }

        &.filled {
            background: $themeColor;
            color: #fff;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.13), 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }
}

.share-video-dialog {
    width: 100%;

    .hdr {
        padding: 12px 20px;
        border-bottom: 1px solid #ddd;
        height: 58px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 10;

        .title {
            flex: 1;
            color: #212121;
        }

        .cross-btn {
            font-size: 28px;
            color: #212121;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .wrap {
        padding: 22px 22px;

        .feild {
            width: 100%;
            margin-bottom: 20px;

            .lbl {
                margin-bottom: 5px;
            }

            .itm {
                border: 1px solid #ddd;
                border-radius: 4px;
                width: 100%;

                .iput {
                    width: 100%;
                    padding: 11px 15px;
                }

                .copy-btn {
                    background: $themeColor;
                    padding: 6px 10px;
                    margin-right: 8px;
                    border-radius: 3px;

                    &:hover {
                        opacity: 0.8;
                    }

                    .copied {
                        position: absolute;
                        top: -33px;
                        right: 0px;
                        z-index: 5;
                        color: #fff;
                        font-size: 12px;
                        background: #121212;
                        padding: 4px 12px;
                        border-radius: 3px;
                    }
                }

                &.area {
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;

                    .iput {
                        height: 120px;
                        resize: none;

                        &::-webkit-scrollbar {
                            width: 4px;
                        }
                    }

                    .copy-btn {
                        margin: 6px 10px 8px 12px;
                    }
                }
            }

            .link-btn {
                background: $themeColor;
                padding: 11px;
                text-align: center;
                border-radius: 4px;
                font-weight: 500;

                &:hover {
                    opacity: 0.8;
                    color: #fff;
                }
            }

            &.checkbox {
                .lbl {
                    margin-bottom: 0px;
                }
            }

            .checkbox-btn {
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: 1px solid #ddd;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include anim;
                margin-right: 10px;

                &:hover {
                    border-color: $themeColor;
                }

                .icon {
                    font-size: 16px;
                    visibility: hidden;
                    opacity: 0;
                }

                &.on {
                    background: $themeColor;

                    .icon {
                        visibility: visible;
                        opacity: 1;
                        color: #fff;
                    }
                }
            }
        }
    }

    .advanced-upload-btns {
        margin-bottom: 18px;

        .record-new-video-btn {
            box-shadow: none;
            padding: 9px 0px;

            &:hover {
                background: transparent;
            }
        }
    }

    .print-btn {
        width: 100%;
        margin-bottom: 8px;

        .btn {
            outline: none;
            background: none;
            width: 50%;
            border: 1px solid rgba(0, 0, 0, 0.3);
            padding: 8px 20px;
            border-radius: 2px;
            text-align: center;

            &:not(:last-child) {
                margin-right: 12px;
            }

            &:hover {
                border-color: $themeColor;
                color: #fff;
                background: $themeColor;
            }
        }
    }
}

// Maget Profiel Page
.magnet-profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;

    @include media('<=phone') {
        padding: 20px 0px;
        background: #fff;
    }

    .content {
        background: #fff;
        padding: 22px 25px 22px 25px;
        width: 500px;

        @include media('<=phone') {
            width: 100%;
            min-height: calc(100vh - 120px);
        }

        .hdr {
            margin-bottom: 15px;

            .title {
                font-size: 20px;
                font-weight: 600;
                color: $black;
                line-height: 1;
                margin-bottom: 5px;
            }

            .txt {
                font-size: 14px;
                color: $black;
                line-height: 1.4;
            }
        }

        .user-dp {
            text-align: center;
            max-width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 5px auto;

            @include media('<=480px') {
                max-width: 100%;
            }

            .img {
                @include bgImage;
                height: 100px;
                width: 100px;
            }

            .img-btn {
                font-size: 13px;
                font-weight: 500;
                color: $themeColor;
                margin-top: 4px;
                cursor: pointer;
                @include anim;

                &:hover {
                    text-decoration: underline;
                }

                &:disabled {
                    cursor: no-drop;
                }
            }
        }

        .form {
            .field {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                .lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: $black;
                    margin-bottom: 4px;
                }

                .iput {
                    width: 100%;
                    outline: none;
                    border: 1px solid $color-black-3;
                    padding: 9px 15px;
                    font-size: 14px;
                    color: $black;
                    @include anim(0.2s);

                    &:focus {
                        border-color: $themeColor;
                    }
                }

                .msg {
                    font-size: 13px;
                    margin-top: 4px;
                    color: $red;
                    line-height: 1.2;
                }
            }

            .form-btn {
                width: 100%;
                background: $themeColor;
                font-size: 14px;
                color: $white;
                font-weight: 500;
                padding: 10px 20px;
                margin-top: 13px;

                &:hover {
                    opacity: 0.9;
                }
            }

            .error-msg {
                font-size: 14px;
                color: $red;
                margin-top: 14px;
            }
        }
    }
}

// Upload Form Page
.upload-form {
    margin: auto;

    .tabs-blk {
        width: 100%;
        border-bottom: 1px solid #ddd;
        margin-bottom: 30px;

        .tab-btn {
            margin-right: 20px;
            border-bottom: 1px solid transparent;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                height: 1.5px;
                background: $themeColor;
                transition: width 0.3s;
                margin-top: 2px;
            }

            &.on {
                color: $themeColor;

                &::after {
                    width: 100%;
                }
            }

            &:hover {
                color: $themeColor;

                &::after {
                    width: 100%;
                }
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    .feild-blk {
        margin-bottom: 20px;

        .lbl {
            margin-bottom: 5px;
            font-size: 0.875rem;
        }
    }

    .links {
        border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
        padding: 12px 0px;
        margin-bottom: 24px;

        .link-btn {
            font-size: 14px;
            border: 1px solid $black;
            padding: 7px 14px;
            margin-right: 10px;
            color: $black;
            font-weight: 500;
            border-radius: 25px;
        }

        .active {
            background: $themeColor;
            color: #fff;
            border: 1px solid #fff;
        }
    }

    .bottom-link-img {
        @include bgImage(3px);
        height: 50px;
        width: 50px;
    }

    .buttons-tabel {
        .btn-lbl {
            font-size: 0.875rem;
            margin-bottom: 8px;
        }
    }

    .prev-mode {
        justify-content: center;
        padding: 7px 20px;
    }
}

.upload-form-links {
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding: 12px 0px;
    margin-bottom: 24px;

    .link-btn {
        font-size: 14px;
        border: 1px solid $black;
        padding: 7px 14px;
        margin-right: 10px;
        color: $black;
        font-weight: 500;
        border-radius: 25px;
    }

    .active {
        background: $themeColor;
        color: #fff;
        border: 1px solid #fff;
    }
}

// Upload Form
.upload-form-blk {
    margin-bottom: 50px;

    @include media('>=phone', '<=480px') {
        flex-direction: column;
        margin-bottom: 40px;
    }

    &:last-child {
        margin-bottom: 0px;
    }

    .hd {
        width: 100%;
        margin-bottom: 11px;

        .tit {
            flex: 1;
        }

        .btn {
            color: #8498a4;

            .ico {
                margin-right: 5px;
            }
        }
    }

    .item {
        flex: 1;
        margin-right: 20px;
        position: relative;

        @include media('>=phone', '<=480px') {
            flex-direction: column;
            margin-right: 0px;
            width: 100%;
            margin-bottom: 28px;
        }

        &:last-child {
            margin-right: 0px;
        }

        .lbl {
            margin-bottom: 4px;
        }

        .feild {
            width: 100%;
            padding: 8px 0px;
            border-bottom: 1px solid #000;
            margin-right: 16px;

            @include media('>=phone', '<=480px') {
                margin-right: 0px;
            }

            &:focus-within {
                border-color: $themeColor;
            }

            .iput {
                width: 100%;

                &::placeholder {
                    color: #aaaaaa;
                }
            }

            .tag {
                height: 22px;
                width: 29px;
                border-radius: 5px;
                justify-content: center;
                background: $black;
                margin-right: 14px;
            }
        }

        .btn {
            border: 1px solid #e5e5e5;
            padding: 8px 16px;
            border-radius: 4px;
            margin-right: 10px;
            min-width: 120px;
            justify-content: center;

            @include media('>=phone', '<=480px') {
                width: 100%;
                margin-right: 0px;
                margin-top: 18px;
            }

            &:last-child {
                margin-right: 0px;
            }

            .lb {
                margin-right: 7px;
            }

            .ico {
                right: 9px;
            }
        }

        .suggest {
            width: 100%;
            position: absolute;
            top: 78px;
            background: #fff;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            overflow: auto;
            max-height: 220px;
            z-index: 9;
            @include hide;

            &::-webkit-scrollbar {
                width: 4px;
            }

            @include dropDownAnim;

            &.on {
                @include show;
            }

            .text {
                padding: 8px 20px;
                cursor: pointer;
                @include anim;

                &:hover {
                    background: #f9f9f9;
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .del-btn {
        padding: 11px 0px;

        @include media('>=phone', '<=480px') {
            padding: 0px 0px;
            margin-top: -8px;
        }
    }
}

// Upload form Clone Dailogbox
.clone-dailog {
    width: 590px;

    .hdr {
        padding: 7px 20px;
        border-bottom: 1px solid #c4c4c4;

        .title {
            width: 100%;
        }
    }

    .upload-form-links {
        border: none;
        padding: 0px 16px;
        margin-top: 26px;
        margin-bottom: 0px;
    }

    .iput-blk {
        padding: 28px 20px 22px 20px;
    }

    .prevList {
        padding: 0px 20px 30px 20px;
    }

    .ftr {
        width: 100%;
        justify-content: flex-end;
        padding: 0px 20px 22px 20px;

        .clone-btn {
            padding: 10px 22px;
            background: $themeColor;
            border-radius: 2px;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

// Additional Page
.adt-page {
    width: 100%;

    .feild-blk {
        width: 100%;
    }

    .feild-lbl {
        margin-bottom: 5px;
    }
}

// magnet setting page
.magnet-setting-page {
    padding: 22px $horizontalPending;
    min-height: 100vh;

    @include media('<=large') {
        padding: 22px 0px;
    }

    .page-title {
        margin-bottom: 15px;
    }

    .wrapper {
        max-width: 75%;

        @include media('<=large') {
            max-width: 100%;
        }

        .section {
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 15px;
            }

            .title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 12px;

                &.holder {
                    height: 20px;
                    width: 250px;
                }
            }

            .wrap {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;

                @include media('<=phoneH') {
                    grid-template-columns: repeat(1, 1fr);
                }

                .block {
                    width: 100%;
                    padding: 14px 15px;
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                    min-height: 105px;
                    cursor: pointer;
                    @include anim;

                    &:hover {
                        filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.08));
                        transform: translateY(-2px);
                    }

                    .meta {
                        margin-bottom: 10px;

                        .icon {
                            margin-right: 10px;
                            height: 22px;

                            &.entrata {
                                margin-right: 3px;
                                margin-left: -9px;
                            }

                            &.email {
                                margin-top: -4px;
                            }

                            .ico {
                                height: auto;
                            }
                        }

                        .lbl {
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }

                    .text {
                        color: rgba(0, 0, 0, 0.33);
                        line-height: 1.5;
                    }
                }

                .holder {
                    height: 115px;
                    width: 100%;
                }
            }
        }
    }

    .integrate-block {
        margin-bottom: 40px;

        .integrate-title {
            margin-bottom: 20px;
        }

        .integrate-wrapper {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-column-gap: 17px;
            grid-row-gap: 17px;

            @include media('<=large') {
                grid-template-columns: repeat(6, 1fr);
            }

            .wrap-block {
                height: 150px;
                width: 150px;
            }
        }

        .integrates-items {
            flex-wrap: wrap;
        }
    }
}

// Analytics page
.analytics-page {
    .tit {
        &.holder {
            width: 250px;
            height: 12px;
            margin-bottom: 22px;
        }
    }

    .duration-drop {
        &.holder {
            width: 100%;
            height: 45px;
            margin-bottom: 22px;
        }
    }

    .analytic-wrap {
        margin-bottom: 22px;

        .graph {
            &.holder {
                width: 100%;
                height: 325px;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }

        .summary {
            width: 40%;

            .summary-item {
                &.holder {
                    height: 70px;
                    width: 100%;
                    margin-bottom: 14px;
                }
            }
        }
    }
}

.MainAnalytics {
    .graph-loading {
        height: 280px;
        width: 100%;
        border-radius: 0px !important;
    }
}

// magnet setting page
.magnet-setting {
    .blk {
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: 0px;
        }

        .tit {
            &.holder {
                width: 220px;
                height: 20px;
                margin-bottom: 22px;
                border-radius: 0px;
            }
        }

        .item {
            &.holder {
                width: 100%;
                height: 30px;
                margin-bottom: 14px;
                border-radius: 0px;
            }
        }
    }
}

.default-emded-setting {
    .title {
        margin-bottom: 20px;
    }

    .wrap {
        .form {
            flex: 1;

            .title {
                margin-bottom: 15px;
            }

            .feild {
                width: 100%;
                margin-bottom: 18px;

                .lbl {
                    margin-bottom: 3px;
                }

                .itm {
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    width: 100%;

                    .iput {
                        width: 100%;
                        padding: 11px 15px;

                        &:hover {
                            border-color: $themeColor;
                        }
                    }

                    &.area {
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: flex-end;

                        .iput {
                            height: 120px;
                            resize: none;

                            &::-webkit-scrollbar {
                                width: 4px;
                            }
                        }
                    }
                }

                .link-btn {
                    background: $themeColor;
                    padding: 11px;
                    text-align: center;
                    border-radius: 4px;
                    font-weight: 500;

                    &:hover {
                        opacity: 0.8;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.setting-integrate-blk {
    margin-bottom: 50px;
}

.dynamic-variables-setting {
    .title {
        margin-bottom: 10px;
    }

    .sub-txt {
        margin-bottom: 16px;
    }

    .variables-wrapper {
        .block {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        .hdr {}

        .col-one {
            flex: 1;
            margin-right: 20px;
        }

        .col-two {
            flex: 2;
            margin-right: 20px;
        }

        .col-three {}

        .label {
            margin-bottom: 5px;
        }

        .iput {
            border: 1px solid #c4c4c4;
            width: 100%;
            padding: 9px 20px;
            border-radius: 5px;
        }

        .del-btn {
            border: 1px solid #c4c4c4;
            padding: 9px 8px 9px 9px;
            border-radius: 8px;
        }
    }

    .ftr {
        flex: 1;
        margin-top: 40px;
        justify-content: flex-end;

        .add-btn {
            background: transparent;
            border: 1px solid #c4c4c4;
            padding: 8px 12px;
            border-radius: 5px;
            margin-right: 12px;

            &:hover {
                opacity: 0.8;
            }
        }

        .save-btn {
            background: $themeColor;
            border: 1px solid $themeColor;
            padding: 8px 12px;
            border-radius: 5px;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.chatbot-setting-dialog {
    @include media('<=tablet') {
        flex-direction: column;
    }

    .ls {
        flex: 1.5;

        .bubbles-list {
            margin-bottom: 14px;

            .bubble {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .bubble-hdr {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin-bottom: 3px;

                    .hdr-btn {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                        color: $black;

                        .ico {
                            margin-right: 5px;
                            color: $black;
                        }
                    }
                }

                .bubble-content {
                    border: 1px solid $borderColor;
                    padding: 14px 12px;
                    border-radius: 4px;

                    .bubble-content-hdr {
                        display: flex;
                        margin-bottom: 12px;

                        .meta {
                            flex: 1;
                            margin-right: 22px;

                            .title {
                                margin-bottom: 2px;
                                line-height: 1;
                            }

                            .disc-input {
                                color: $black;
                                font-size: 14px;
                                width: 100%;
                                border-bottom: 1px solid #c4c4c4;
                                padding: 4px 0px;
                                margin: 3px 0px;
                            }
                        }

                        .rit {
                            display: flex;
                            flex-direction: column;

                            .hdr-btn {
                                display: flex;
                                align-items: center;
                                font-size: 14px;
                                font-weight: 500;
                                color: $themeColor;

                                &:not(:last-child) {
                                    margin-bottom: 5px;
                                }

                                .ico {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }

                    .variables-wrapper {
                        .block {
                            margin-bottom: 12px;

                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }

                        .block-col {
                            flex: 1;
                            margin-right: 10px;
                            display: flex;
                            align-items: center;

                            &:first-child,
                            &:last-child {
                                max-width: fit-content;
                            }

                            &:last-child {
                                margin-right: 0px;
                            }

                            .field {
                                width: 100%;
                            }
                        }

                        .label {
                            margin-bottom: 5px;
                        }

                        .iput {
                            border: 1px solid #c4c4c4;
                            width: 100%;
                            padding: 9px 20px;
                            border-radius: 5px;
                        }

                        .del-btn {
                            border: 1px solid #c4c4c4;
                            padding: 9px 8px 9px 9px;
                            border-radius: 8px;

                            &:not(:last-child) {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        .add-bubble-btn {
            border: 1px solid #333;
            width: max-content;
            padding: 8px 11px;
            cursor: pointer;
            border-radius: 3px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:disabled {
                opacity: 0.7;
            }
        }
    }

    .rs {
        flex: 1;
        margin-left: 60px;
        display: flex;
        flex-direction: column;

        @include media('<=medium') {
            margin-left: 30px;
        }

        @include media('<=tablet') {
            margin-left: 0px;
            margin-top: 40px;
        }

        .chatbot-preview {
            display: flex;
            flex-direction: column;

            .tit {
                margin-bottom: 10px;
                font-weight: 600;
            }

            .chatbot-bubbles {
                width: 350px;
                min-width: 350px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                transition: all 0.3s linear;

                @include media('<=phone') {
                    width: 320px;
                }

                @include media('<=380px') {
                    width: 100%;
                }

                .initial-message-bubble {
                    position: relative;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    padding: 10px;
                    width: 100%;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    transition: all 0.3s linear;
                    z-index: 2147483647;
                    background-color: rgba(255, 255, 255, 0.3);
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter: blur(10px);

                    &:last-child {
                        &::before {
                            content: '';
                            position: absolute;
                            left: 50px;
                            bottom: -10px;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid rgba(255, 255, 255, 0.3);
                        }
                    }

                    .message-title {
                        font-family: 'Inter', sans-serif;
                        font-weight: 600;
                        font-size: 15px;
                        margin-bottom: 5px;
                        text-align: left;
                    }

                    .message-text {
                        font-family: 'Inter', sans-serif;
                        font-size: 14px;
                        color: #212121;
                        line-height: 1.5;
                        text-align: left;
                    }

                    .bubble-buttons {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-top: 7px;

                        .bubble-btn {
                            outline: none;
                            border: none;
                            background: none;
                            font-family: 'Inter', sans-serif;
                            padding: 4px 8px;
                            color: #212121;
                            border: 1px solid rgba(0, 0, 0, 0.3);
                            border-radius: 30px;
                            font-size: 13px;
                            transition: all 0.3s linear;
                            margin: 3px;
                            cursor: pointer;

                            &:hover {
                                background: #212121;
                                border-color: #212121;
                                color: #fff;
                            }
                        }
                    }

                    /* transparent bubble */
                    &.transparent {
                        border: none;
                        padding: 0;
                        border-radius: 0;
                        backdrop-filter: none;
                        background: transparent;

                        .bubble-buttons {
                            margin-top: 0px;
                        }

                        .bubble-btn {
                            margin: 0;
                            outline: none;
                            border: none;
                            background: white;
                            border-radius: 7px;
                            font-size: 14px;
                            color: #333;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            padding: 10px;
                            width: 100%;
                            max-width: max-content;

                            &.full {
                                max-width: 100%;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .initial-message-bubble.transparent .bubble-buttons .bubble-btn:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .copy-snippet {
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.3);
            padding: 12px;
            height: 150px;
            resize: none;
            border-radius: 4px;

            &::-webkit-scrollbar {
                width: 5px;
            }
        }

        .copy-btn {
            outline: none;
            border: 1px solid $lightBlack;
            color: $black;
            font-size: 14px;
            font-weight: 500;
            padding: 9px 20px;
            border-radius: 3px;
            margin-top: 12px;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }

            .copied {
                position: absolute;
                top: -33px;
                right: 0px;
                z-index: 5;
                color: #fff;
                font-size: 12px;
                background: #121212;
                padding: 4px 12px;
                border-radius: 3px;
            }
        }

        .preview-btn {
            outline: none;
            border: 1px solid #33A8FF;
            color: #33A8FF;
            font-size: 14px;
            font-weight: 500;
            padding: 9px 20px;
            border-radius: 3px;
            margin-top: 12px;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }

            .copied {
                position: absolute;
                top: -33px;
                right: 0px;
                z-index: 5;
                color: #fff;
                font-size: 12px;
                background: #121212;
                padding: 4px 12px;
                border-radius: 3px;
            }
        }
    }
}

.special-offer-dialog {
    .field {
        width: 100%;
        margin-bottom: 15px;

        .iput {
            outline: none;
            border: 1px solid #c4c4c4;
            width: 100%;
            padding: 9px 20px;
            border-radius: 5px;
            font-size: 15px;
        }
    }
}

.custom-branding-dialog {
    .form {
        align-items: flex-start;

        .image-blk {
            display: flex;
            flex-direction: column;
            max-width: fit-content;
            margin-bottom: 18px;
            height: auto;

            .brand-logo {
                overflow: hidden;
                margin-bottom: 8px;
                width: 120px;
                height: 120px;
                background-color: transparent;
                border: 1px solid $black;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .rit {
            flex: 1;

            .field {
                width: 100%;
                margin-bottom: 15px;

                .iput {
                    outline: none;
                    border: 1px solid #c4c4c4;
                    width: 100%;
                    padding: 9px 20px;
                    border-radius: 5px;
                    font-size: 15px;
                }

                .save-brand {
                    background: $themeColor;
                    color: #fff;
                    font-size: 14px;
                    padding: 10px 20px;
                    border-radius: 4px;
                }
            }
        }
    }
}

.share-page-settings {
    .section {
        margin-top: 30px;

        &:first-child {
            margin-top: 0px;
        }

        .title {
            &.features-title {
                margin-bottom: 10px;
            }
        }

        .add-feature {
            background: $themeColor;
            padding: 10px 20px;
            border-radius: 3px;
            max-width: fit-content;
            margin-top: 10px;
        }
    }
}

// Embed Tab Page
.embed-steps {
    padding: 60px 0px;
    justify-content: space-between;

    .step-block {
        padding: 20px;

        &:hover,
        &.on {
            background: #f0f4fd;
        }

        .meta {
            justify-content: center;
            background: #fff;
            padding: 20px;
            border: 1px solid #e5e5e5;
            width: 200px;
            height: 200px;
            border-radius: 15px;

            .icon {
                height: 57px;
            }

            .lbl {
                padding: 15px 0px 10px 0px;
            }

            .txt {
                text-align: center;
            }
        }
    }
}

// Embed Page Inline Block
.embed-inline-blk {
    .inline-title {
        margin-bottom: 14px;
    }

    .inline-wrap {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        padding: 25px;
        border-radius: 12px;

        .iframe-blk {
            width: 55%;
            margin-right: 30px;

            iframe {
                outline: none;
                border: none;
                width: 100%;
            }
        }

        .inline-iframe-form {
            flex: 1;

            .title {
                margin-bottom: 15px;
            }

            .hdr {
                margin-bottom: 30px;

                .MuiFormControlLabel-root {
                    margin-right: 12px;

                    &:last-child {
                        margin-right: 0px;
                    }
                }

                .MuiTypography-body1 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #121212;
                    font-family: 'Poppins', sans-serif;
                }
            }

            .feild {
                width: 100%;
                margin-bottom: 20px;

                .lbl {
                    margin-bottom: 5px;
                }

                .itm {
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    width: 100%;

                    .iput {
                        width: 100%;
                        padding: 11px 15px;
                    }

                    .copy-btn {
                        background: $themeColor;
                        padding: 6px 10px;
                        margin-right: 8px;
                        border-radius: 3px;

                        &:hover {
                            opacity: 0.8;
                        }

                        .copied {
                            position: absolute;
                            top: -33px;
                            right: 0px;
                            z-index: 5;
                            color: #fff;
                            font-size: 12px;
                            background: #121212;
                            padding: 4px 12px;
                            border-radius: 3px;
                        }
                    }

                    &.area {
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: flex-end;

                        .iput {
                            height: 120px;
                            resize: none;

                            &::-webkit-scrollbar {
                                width: 4px;
                            }
                        }

                        .copy-btn {
                            margin: 6px 10px 8px 12px;
                        }
                    }
                }

                .link-btn {
                    background: $themeColor;
                    padding: 11px;
                    text-align: center;
                    border-radius: 4px;
                    font-weight: 500;

                    &:hover {
                        opacity: 0.8;
                        color: #fff;
                    }
                }
            }
        }
    }
}

// magnet team page
.magnent-team-page {
    padding: 22px $horizontalPending;
    overflow: hidden;
    min-height: 100vh;

    @include media('<=large') {
        padding: 22px 0px;
    }

    .hdr {
        .tit {
            &.holder {
                width: 260px;
                height: 20px;
                border-radius: 0px;
            }
        }

        .lbl {
            &.holder {
                width: 180px;
                height: 20px;
                border-radius: 0px;
                margin-top: 10px;
            }
        }
    }

    .team-wrap {
        margin: 20px 0px;

        .item {
            &.holder {
                width: 33%;
                height: 32px;
                border-radius: 100px;
                margin: 10px;
            }
        }
    }

    .bt {
        &.holder {
            width: 160px;
            height: 44px;
            margin-top: 24px;
            border-radius: 0px;
        }
    }
}

//videoembed Analytics page
.videomagnet-analytics {
    .states-blk {
        background: #fff;
        padding: 28px 20px 32px 20px;
        margin-top: 24px;

        .state-title {
            margin-bottom: 20px;
        }

        .states-items {
            flex: 1;

            .state-item {
                flex: 1;
                border: 1px solid #ddd;
                padding: 10px;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0px;
                }

                .ico {
                    height: 44px;
                    width: 44px;
                    border-radius: 3px;
                    justify-content: center;
                    margin-right: 12px;
                    color: $themeColor;
                    background: rgba(56, 152, 236, 0.2);
                }
            }
        }
    }

    .leads-states {
        margin-top: 0px;
        margin-bottom: 35px;
    }
}

// videoembed leads page
.leads-comp {
    .step-col {
        .no {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            justify-content: center;
            margin-bottom: 2px;
            background: rgba(56, 152, 236, 0.2);
        }
    }

    .date-col {
        background: $themeColor;
        border-radius: 3px;
        padding: 1px 5px;
        margin: 3px;
        font-size: 12px;
        width: fit-content;
    }

    .actions {
        background-color: #777;
    }
}

// videoembed embed page
.video-magnet-embed-page {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
    width: 100%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-radius: 3px;

    .emded-page-body {
        flex: 1 1 auto;
        margin: 0;
        padding: 1.5rem 1.5rem;
        position: relative;

        .embed-container {
            width: 100%;

            .col {
                flex: 1;

                .blk {
                    max-width: 220px;
                }
            }
        }
    }
}

// Embed Tab Pages
.embed-tab-page {
    padding: 22px $horizontalPending;
    overflow: hidden;

    @include media('<=large') {
        padding: 22px 0px;
    }
}

// Admin Tab Pages
.admin-tab-page {
    padding: 22px $horizontalPending;

    @include media('<=large') {
        padding: 22px 0px;
    }
}

// Tour magnet tabs analytics page
.tour-magnet-analytics-p {
    padding: 22px $horizontalPending;
    overflow: hidden;

    .Card {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        position: relative;
        margin-bottom: 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 40, 100, 0.12);
        border-radius: 3px;
    }

    .page-content {
        margin: 1.5rem 0;
    }

    .community-name {
        margin-left: 60px;
    }

    .live-visitor {
        display: flex;
        margin-bottom: 18px;
        margin-bottom: -80px;
        margin-left: 60px;

        .circle {
            height: 15px;
            width: 15px;
            background-color: #8ae18a;
            border-radius: 50%;
            display: inline-block;
        }

        .text-block {
            margin-left: 10px;
        }
    }

    .analytics-range-header {
        // background-color: black;
        background: #2980b9;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #d0e3f0, #d9e3e7);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #d0e3f0, #d9e3e7);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color: rgb(80, 80, 80);
        padding: 12px;
        font-weight: 800;
    }

    .p-header {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        flex-wrap: wrap;
    }

    .state-item {
        flex: 1;
        border: 1px solid #ddd;
        padding: 10px;
        margin-right: 10px;

        &:last-child {
            margin-right: 0px;
        }

        .ico {
            height: 44px;
            width: 44px;
            border-radius: 3px;
            justify-content: center;
            margin-right: 12px;
            color: $themeColor;
            background: rgba(56, 152, 236, 0.2);
        }
    }
}

// Add Category Button
.add-category-btn-blk {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 5px;

    .add-category-button {
        background: rgb(238, 241, 242);
        width: 100%;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 6px;

        &:hover {
            background: $themeColor;
            color: #fff;
        }

        &:disabled {
            cursor: no-drop;

            &:hover {
                background: rgb(238, 241, 242);
                color: #333;
            }
        }

        .ico-pls {
            margin-right: 3px;
        }
    }
}

.MuiDialog-paperScrollPaper {
    &::-webkit-scrollbar {
        width: 5px;
    }
}

// New Video Button Dialogbox Wrapper
.new-screen-container {
    width: 100%;
    min-height: 600px;
    border-radius: 12px;
    position: relative;

    &::-webkit-scrollbar {
        width: 5px;
    }

    .choose-screen-blk {
        height: 100%;

        .hdr {
            padding: 10px 14px 10px 20px;
            border-bottom: 1px solid $borderColor;

            .title {
                flex: 1;
            }

            .cross-btn {
                height: 36px;
                width: 36px;
                min-width: 36px;
                border-radius: 50%;
                line-height: 1;
                padding-bottom: 4px;
                justify-content: center;

                &:hover {
                    background: #f8f8f8;
                }
            }
        }

        .wrap {
            padding: 22px 20px;

            .feild {
                .label {
                    margin-bottom: 5px;
                }

                .MuiOutlinedInput-input {
                    padding: 15.5px 14px;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: $borderColor;
                }
            }

            .types {
                margin-top: 30px;

                .label {
                    padding: 8px 0px;
                }

                .blocks {
                    .blk {
                        border: 1px solid $borderColor;
                        padding: 11px 15px;
                        border-radius: 6px;
                        margin-bottom: 20px;
                        @include anim;
                        cursor: pointer;

                        &.active {
                            background: #f0f4fd;
                            border: 1px solid #3898ec;

                            .ico {
                                background: $themeColor;

                                svg {
                                    path {
                                        fill: #fff;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .ico {
                            height: 50px;
                            width: 50px;
                            justify-content: center;
                            border-radius: 7px;
                            border: 1.5px solid #3898ec;
                            @include anim;

                            @include media('<=480px') {
                                height: 45px;
                                width: 45px;
                            }

                            svg {
                                height: 18px;

                                path {
                                    @include anim;
                                }
                            }
                        }

                        .meta {
                            margin-left: 20px;

                            .lbl {}

                            .txt {
                                color: #aaaaaa;
                                font-weight: 300;
                                margin-top: 3px;
                            }
                        }

                        &:nth-child(1) {
                            .ico {
                                svg {
                                    height: 14px;
                                }
                            }
                        }

                        &:nth-child(3) {
                            .ico {
                                svg {
                                    height: 21px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ftr {
            justify-content: flex-end;
            padding: 16px 22px 22px 20px;

            .next-btn {
                background: $themeColor;
                padding: 7px 20px;
                border-radius: 3px;
                letter-spacing: 0.3px;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}

// Video Upload Dialog
.upload-container {
    width: 100%;
    min-height: 600px;
    border-radius: 12px;
    position: relative;

    .dialog-hdr {
        top: 0;
        left: 0;
        right: 0;
        position: sticky;
        height: 60px;
        padding: 10px 14px;

        .cross-btn {
            font-size: 30px;
            color: #212121;
            position: absolute;
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding-bottom: 3px;
            background: 'transparent';
            @include anim;

            &:hover {
                background: #f1f1f1;
            }
        }

        .skip-btn {
            position: absolute;
            right: 16px;

            &:hover {
                text-decoration: underline;
            }
        }

        .steps {
            flex: 1;
            justify-content: center;

            .step {
                height: 22px;
                width: 22px;
                border-radius: 50%;
                justify-content: center;
                font-size: 12px;
                color: #c4c4c4;
                border: 1px solid #c4c4c4;

                &.on {
                    color: $themeColor;
                    border-color: $themeColor;
                }

                &.checked {
                    background: #0bc573;
                    border-color: #0bc573;
                }
            }

            .line {
                width: 22px;
                height: 1px;
                background: #0bc573;
                margin: 0 5px;

                &.sho {
                    opacity: 1;
                }

                &.hid {
                    opacity: 0;
                }
            }
        }
    }

    .template-ftr {
        background: #3793ed;
        height: 60px;
        width: 100%;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .ico {
            margin-right: 8px;
            height: 18px;
        }

        .txt {
            text-align: center;
            cursor: pointer;
            font-weight: 300;
        }
    }
}

.new-video {
    .step-first {
        height: 100%;
        cursor: pointer;

        .vector {
            height: 150px;
            margin: 30px 20px 30px 20px;
        }

        .slogn {
            text-align: center;
            margin-bottom: 30px;
            padding: 0px 90px;
            line-height: 1.6;
        }

        .upload-btn {
            align-items: center;
            justify-content: center;

            .txt {
                color: #b0b0b0;
            }

            .ico {
                margin-left: 8px;
                line-height: 1;
                width: 12px;
            }
        }
    }

    .step-two {
        .meta {
            flex: 1;
            justify-content: space-between;
            margin: 14px 22px;

            .tit {}

            .upload-btn {
                .ico {
                    margin-left: 7px;
                    margin-bottom: 4px;
                    font-size: 16px;
                }
            }
        }

        .wrap {
            margin: 22px 22px;
            min-height: calc(100vh - 390px);

            .video-card {
                border: 1px solid #e5e5e5;
                padding: 7px 10px;
                border-radius: 8px;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0px;
                }

                .video {
                    height: 70px;
                    min-height: 70px;
                    width: 70px;
                    min-width: 70px;
                    background: #e2e2e2;
                    border-radius: 10px;
                    margin-right: 16px;
                    object-fit: cover;
                }

                .lbl {
                    flex: 1;
                }

                .actions {
                    padding-right: 5px;
                    flex: 1;
                    justify-content: flex-end;

                    .icon {
                        color: #8498a4;
                        font-size: 20px;
                        height: 35px;
                        width: 35px;
                        border-radius: 50%;
                        @include anim;

                        &:hover {
                            background: #f7f7f7;
                            color: #000;
                        }
                    }
                }
            }
        }

        .ftr {
            width: 100%;
            justify-content: center;
            margin-bottom: 28px;
            margin-top: 10px;

            .create-btn {
                padding: 7px 30px;
                border: 1px solid $themeColor;
                color: $themeColor;
                border-radius: 4px;

                &:hover {
                    background: $themeColor;
                    color: #fff;
                }
            }
        }
    }
}

.dragging-area {
    position: absolute;
    top: 52px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: hidden;

    .dragger-indicator {
        border: 4px #ccc dashed;
        background-color: rgba(248, 248, 248, 0.97);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        width: 100%;
        height: 100%;
    }
}

.new-iframe {
    padding: 10px 20px;

    .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px 40px 30px 40px;
        border: 1px solid #3793ed;
        border-radius: 12px;

        .icon {
            margin-bottom: 50px;

            svg {
                height: 90px;
                width: 90px;
            }
        }

        .tit {
            margin-top: 20px;
            text-align: center;
            letter-spacing: 0.3px;
            line-height: 1.6;
        }
    }

    .feild {
        width: 100%;
        background: #f7f7f7;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        margin-top: 50px;

        .iput {
            flex: 1;
            width: 100%;
            padding: 10px 15px;
        }

        .feild-btn {
            height: 100%;
            padding: 6px 12px;
            margin-right: 4px;
            background: $themeColor;
            color: #fff;
            border-radius: 4px;
            letter-spacing: 0.2px;
        }
    }

    .new-question-wrap {
        background: red;

        .feild-title {
            font-weight: 500;
            font-size: 0.75rem;
            font-family: 'Poppins', sans-serif;
        }

        .MuiInputBase-input {
            font-size: 14px;
        }
    }
}

.new-form {
    .step-first {
        height: 100%;
        cursor: pointer;

        .vector {
            height: 150px;
            margin: 30px 20px 30px 20px;
        }

        .slogn {
            text-align: center;
            margin-bottom: 30px;
            padding: 0px 50px;
            line-height: 1.6;
        }

        .upload-btn {
            align-items: center;
            justify-content: center;

            .txt {
                color: #b0b0b0;
            }

            .ico {
                margin-left: 8px;
                line-height: 1;
                width: 12px;
                color: #b0b0b0;
            }
        }
    }

    .step-two {
        .meta {
            flex: 1;
            justify-content: space-between;
            margin: 14px 22px;

            .tit {}

            .upload-btn {
                .ico {
                    margin-left: 7px;
                    margin-bottom: 4px;
                    font-size: 16px;
                }
            }
        }

        .wrap {
            margin: 22px 22px;
            min-height: calc(100vh - 390px);

            .video-card {
                border: 1px solid #e5e5e5;
                padding: 7px 10px;
                border-radius: 8px;
                margin-bottom: 25px;

                .video {
                    height: 70px;
                    min-height: 70px;
                    width: 70px;
                    min-width: 70px;
                    background: #e2e2e2;
                    border-radius: 10px;
                    margin-right: 16px;
                    object-fit: cover;
                }

                .lbl {
                    flex: 1;
                }

                .actions {
                    padding-right: 5px;
                    flex: 1;
                    justify-content: flex-end;

                    .icon {
                        color: #8498a4;
                        font-size: 20px;
                        height: 35px;
                        width: 35px;
                        border-radius: 50%;
                        @include anim;

                        &:hover {
                            background: #f7f7f7;
                            color: #000;
                        }
                    }
                }
            }

            .feild {
                background: #f7f7f7;
                border: 1px solid rgba(0, 0, 0, 0.07);
                width: 100%;
                padding: 10px 20px;
                border-radius: 5px;
                margin-bottom: 16px;

                .iput {
                    width: 100%;
                }
            }

            .add-q-btn {
                margin-top: 8px;
            }
        }

        .ftr {
            width: 100%;
            justify-content: center;
            margin-bottom: 28px;
            margin-top: 10px;

            .create-btn {
                padding: 7px 30px;
                border: 1px solid $themeColor;
                color: $themeColor;
                border-radius: 4px;

                &:hover {
                    background: $themeColor;
                    color: #fff;
                }
            }
        }
    }
}

@mixin dropDownAnim($duration: 0.3s) {
    animation-name: drop;
    animation-duration: $duration;
    transform-origin: top;
}

// Record joy Dialog
.record-joy-component {}

.recorderCountDown {
    justify-content: center;
    background-color: rgba(17, 24, 39, 0.9);
    inset: 0;
    position: absolute;
    z-index: 9999999999;

    &.sho {
        @include show();
    }

    &.hid {
        @include hide();
    }

    .counter {
        font-size: 10rem;
        font-weight: 500;
        text-shadow: 1px 1px 7px #1b3954;
    }
}

.record-joy-dialog {
    width: 670px;
    min-height: 573px;

    @include media('<=phoneH') {
        width: 100%;
        min-height: auto;
    }

    .cross-btn {
        position: absolute;
        right: 13px;
        top: 12px;
        color: #565656;
        height: 31px;
        width: 31px;
        min-width: 31px;
        background: #f8f8f8;
        justify-content: center;
        border-radius: 50%;
        font-size: 28px;
        padding-bottom: 4px;
        @include anim;

        @include media('<=phoneH') {
            right: 2px;
            top: 2px;
        }

        &:hover {
            background: $themeColor;
            color: #fff;
        }
    }

    .hdr {
        width: 100%;
        justify-content: center;
        padding: 18px 15px 15px 15px;

        .hdr-btn {
            padding: 9px 10px;
            border-radius: 5px;
            border: 1px solid #c5c5c5;
            margin-right: 14px;

            @include media('<=480px') {
                padding: 7px 8px;
                margin-right: 5px;
            }

            &:last-child {
                margin-right: 0px;
            }

            .ico {
                margin-bottom: 5px;
                font-size: 20px;
            }

            .lbl {
                font-size: 13px;
                font-weight: 400;
            }

            &.on {
                border-color: $themeColor;
                background: #f0f4fd;
            }

            &:hover {
                border-color: grey;
                background: #e5e5e5a0;

                &.on {
                    border-color: $themeColor;
                    background: #f0f4fd;
                }
            }
        }
    }

    .record-container {
        width: 100%;
        padding: 0px 70px 0px 70px;

        @include media('<=phoneH') {
            padding: 0px 22px 0px 40px;
        }

        .screen-layout {
            width: 100%;
            padding: 10px 0px 30px 0px;

            .note-btn {
                position: absolute;
                top: 0;
                right: 0;
                margin: 10px;
                background: $themeColor;
                color: #fff;
                z-index: 9;
                display: flex;
                align-items: center;
                padding: 5px 6px;
                border-radius: 5px;
                font-size: 12px;

                .icon {
                    margin-right: 5px;
                }
            }

            .video-block-overlay {
                position: absolute;
                inset: 0;
                background: rgba(0, 0, 0, 0.5);
                padding: 20px;
                @include anim;

                .meta {
                    width: 80%;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    margin-top: 15px;

                    .title {
                        text-align: center;
                        font-size: 16px;
                    }

                    .field {
                        width: 100%;
                        z-index: 2;
                        margin-top: 16px;

                        .iput {
                            border: 1px solid #fff;
                            width: 100%;
                            padding: 10px 20px;
                            border-radius: 3px;
                            outline: none;
                            background: transparent;

                            &.area {
                                resize: none;
                                height: 170px;
                            }
                        }
                    }
                }
            }

            .video-block {
                height: 300px;
                width: 100%;
                background: #e2e2e2;
                border-radius: 12px;
                overflow: hidden;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);

                @include media('<=480px') {
                    height: 220px;
                }

                .video {
                    flex-shrink: 0;
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    transition: all 0.2s ease-in-out;
                    object-fit: cover;

                    &.picinPicVideo {}
                }

                .vid-stamp {
                    justify-content: center;
                    bottom: 15px;
                    left: 0;
                    right: 0;

                    .item {
                        background: $themeColor;
                        padding: 1.8px 12px;
                        border-radius: 8px;
                        letter-spacing: 0.2px;

                        .ico {
                            margin-right: 5px;
                        }
                    }
                }
            }

            .video-acitons {
                position: absolute;
                left: -38px;
                bottom: 30px;

                .video-action-btn {
                    font-size: 18px;
                    margin-bottom: 8px;
                    color: #121212;
                    @include anim(0.2s);
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;

                    &:hover {
                        background: #f5f5f5;
                        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
                    }

                    &:disabled {
                        opacity: 0.6;
                        cursor: no-drop;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            .sticker-layout {
                position: absolute;
                left: 20px;
                bottom: 35px;

                .sticker {
                    min-width: 120px;
                    min-height: 120px;
                    max-width: 120px;
                    max-height: 120px;
                    overflow: hidden;
                    border-radius: 100% 100% 100% 10px;
                    -webkit-transition: all 0.2s;
                    transition: all 0.2s;
                    background: #fff;
                    background-size: contain;
                    background-repeat: no-repeat;
                    box-shadow: 0 0 8px 4px rgba(150, 150, 150, 0.3);
                    object-fit: cover;
                }

                .sticker-cross-btn {
                    position: absolute;
                    top: 4px;
                    right: -2px;
                    border-radius: 50px;
                    height: 15px;
                    width: 15px;
                    min-width: 14px;
                    font-size: 14px;
                    background: #fff;
                    justify-content: center;
                }
            }
        }

        .ftr {
            width: 100%;
            justify-content: center;
            margin-bottom: 25px;

            @include media('<=phoneH') {
                flex-direction: column;
            }

            .recording-btn {
                background: $themeColor;
                padding: 13px 24px;
                border-radius: 5px;
                margin-right: 15px;

                @include media('<=phoneH') {
                    margin-right: 0px;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    margin-bottom: 12px;
                }

                &:last-child {
                    margin-right: 0px;
                    margin-bottom: 0px;
                }

                &:hover {
                    transform: scale(1.02);
                }

                &.upload {
                    background: transparent;
                    border: 1px solid #d6d4f8;

                    &:hover {
                        transform: scale(1);
                        border-color: $themeColor;
                        background-color: $themeColor;

                        .ico,
                        .lbl {
                            color: #fff;
                        }
                    }
                }

                .dot {
                    height: 12px;
                    width: 12px;
                    background: #fff;
                    border-radius: 50%;
                    margin-right: 16px;
                }

                .ico {
                    margin-right: 8px;
                    font-size: 19px;
                }

                .lbl {
                    letter-spacing: 0.1px;
                }
            }
        }
    }

    .recorded-blk {
        background: #f1f5f8;
        height: 70px;
        position: sticky;
        bottom: 0;
        right: 0;
        left: 0;
        overflow-y: hidden;
        overflow-x: auto;

        .label {
            text-align: center;
            width: 100%;
            color: #686868;
        }

        .wrap {
            height: 100%;
            padding: 4px 6px;
            overflow: initial;

            ::-webkit-scrollbar {
                width: 2px;
            }

            .item {
                height: 100%;
                width: 100px;
                border-radius: 5px;
                background: #cdcdcd;
                overflow: hidden;
                margin-right: 10px;
                cursor: pointer;

                .overlay {
                    position: absolute;
                    inset: 0;
                    background-color: rgba(27, 57, 84, 0.45);
                    z-index: 1;
                }

                .vid {
                    cursor: pointer;
                    height: 100%;
                    width: 100%;
                }

                &:last-child {
                    margin-right: 0px;
                }

                .video {
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    transition: all 0.2s ease-in-out;
                    object-fit: cover;
                    background: #cdcdcd;
                }

                .stamp {
                    color: #fff;
                    position: absolute;
                    bottom: 4px;
                    right: 5px;
                    font-size: 10px;
                    font-weight: 500;
                    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
                    cursor: inherit;
                    z-index: 3;
                }

                .but {
                    height: 13px;
                    width: 13px;
                    min-width: 13px;
                    font-size: 8px;
                    border-radius: 50px;
                    background: #ffff;
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    z-index: 3;
                }
            }
        }
    }

    .preview-container {
        .wrap {
            padding: 90px 70px 0px 70px;

            @include media('<=phoneH') {
                padding: 72px 20px 0px 20px;
            }

            .meta {
                .back-btn {
                    background: #f8f8f8;
                    height: 32px;
                    width: 36px;
                    justify-content: center;
                    font-size: 22px;
                    border-radius: 5px;
                    color: #565656;
                    position: absolute;
                    top: 13px;
                    left: 16px;
                    cursor: pointer;
                    @include anim;

                    &:hover {
                        background: $themeColor;
                        color: #fff;
                    }
                }
            }

            .screen-layout {
                width: 100%;

                .video-block {
                    height: 300px;
                    width: 100%;
                    background: #e2e2e2;
                    border-radius: 12px;
                    overflow: hidden;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);

                    @include media('<=480px') {
                        height: 250px;
                    }

                    .video {
                        flex-shrink: 0;
                        height: 100%;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        transition: all 0.2s ease-in-out;
                        object-fit: cover;
                    }

                    .vid-stamp {
                        justify-content: center;
                        bottom: 15px;
                        left: 0;
                        right: 0;

                        .item {
                            background: $themeColor;
                            padding: 1.8px 12px;
                            border-radius: 8px;
                            letter-spacing: 0.2px;

                            .ico {
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .sticker-layout {
                    position: absolute;
                    left: 20px;
                    bottom: 35px;

                    .sticker {
                        min-width: 120px;
                        min-height: 120px;
                        max-width: 120px;
                        max-height: 120px;
                        overflow: hidden;
                        border-radius: 100% 100% 100% 10px;
                        -webkit-transition: all 0.2s;
                        transition: all 0.2s;
                        background: #fff;
                        background-size: contain;
                        background-repeat: no-repeat;
                        box-shadow: 0 0 8px 4px rgba(150, 150, 150, 0.3);
                        object-fit: cover;
                    }

                    .sticker-cross-btn {
                        position: absolute;
                        top: 4px;
                        right: -2px;
                        border-radius: 50px;
                        height: 15px;
                        width: 15px;
                        min-width: 14px;
                        font-size: 14px;
                        background: #fff;
                        justify-content: center;
                    }
                }
            }

            .ftr {
                margin: 37px;
                justify-content: center;

                .but {
                    border: 1px solid #d6d4f8;
                    padding: 8px 14px;
                    border-radius: 10px;

                    .lbl {
                        padding: 0px 10px;
                    }
                }
            }
        }
    }
}

// Screen Sharing Error Dialogbox
.screen-error-dialog {
    width: 450px;

    .hdr {
        background: #f5f5f5;
        border-bottom: 1px solid #dbdbdb;
        padding: 18px 22px;
        justify-content: center;
        height: 62px;

        .title {
            color: #363636;
        }
    }

    .content {
        padding: 22px;

        .msg {
            .ico {
                height: 36px;
                width: 36px;
                min-width: 36px;
                background: $themeColor;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 22px;
                border-radius: 50%;
                color: #fff;
                font-weight: 600;
                margin-right: 12px;
            }

            .lbl {
                text-align: center;
            }
        }
    }

    .ftr {
        background: #f5f5f5;
        border-top: 1px solid #dbdbdb;
        padding: 18px 22px;
        justify-content: flex-end;
        height: 68px;

        .close-btn {
            background: $themeColor;
            padding: 8px 28px;
            border-radius: 4px;
            color: #fff;
            font-size: 16px;
            letter-spacing: 0.3px;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

// Recording Setting Dialogbox
.media-setting-dialog {
    width: 500px;

    @include media('<=phoneH') {
        width: 100%;
    }

    .hdr {
        border-bottom: 1px solid #dbdbdb;
        padding: 12px 20px;
        justify-content: center;
        height: 58px;

        .title {}
    }

    .wrap {
        padding: 22px 22px;

        .feild {
            width: 100%;
            margin-bottom: 22px;

            &.active-noice {
                margin-top: 10px;

                .lbl {
                    flex: 1;
                    margin-bottom: 0px;
                }
            }

            .lbl {
                margin-bottom: 3px;
                text-align: start;
            }

            .cstm-slt {
                border: 1px solid #dbdbdb;
                border-radius: 4px;
                padding: 10px 25px 10px 15px;
            }
        }

        .ftr {
            justify-content: flex-end;
            margin-top: 20px;

            .close-btn {
                background: $themeColor;
                padding: 6px 18px;
                border-radius: 11px;
            }
        }
    }
}

@keyframes gooeyIn {
    45% {
        transform: scaleX(1.55);
    }
}

@keyframes gooeyOut {
    55% {
        transform: scaleX(1.55);
    }
}

// Toggle CheckBox Button
.toggle-checkbox {
    flex-shrink: 0;
    width: 42px;
    height: 22px;
    padding: 0.2em;
    background: #b5b5b5;
    border-radius: 21px;

    &:hover {
        box-shadow: 0 0 0.5em rgba(122, 122, 122, 0.6);
    }

    &.on {
        background: $themeColor;
        box-shadow: 0 0 0.5em rgba(56, 152, 236, 0.8);

        .circle {
            background: #fff;
            left: 21.5px;

            &.gooeyIn {
                animation-name: gooeyOut;
                animation-duration: 0.4s;
            }

            &.gooeyOut {
                animation-name: gooeyIn;
                animation-duration: 0.4s;
            }
        }
    }

    .circle {
        height: 18px;
        width: 18px;
        min-width: 18px;
        background: #fff;
        top: 2px;
        left: 1.7px;
        border-radius: 50%;
        @include anim(0.2s);
    }
}

// Custom Selector Input
.cstm-slt {
    border: 1px solid #e5e5e5;
    padding: 10px 18px;
    min-height: 43px;

    &:hover {
        border-color: rgb(181, 181, 181);
    }

    .iput {
        width: 100%;

        .txt {
            flex: 1;
            text-align: start;
        }
    }

    .arrow-ico {
        position: absolute;
        right: 8px;
        font-size: 20px;
    }

    .options {
        background: #fff;
        border-radius: 5px;
        top: 45px;
        left: 0px;
        right: 0;
        max-height: 380px;
        width: auto;
        overflow: auto;
        z-index: 5;
        border: 1px solid #e5e5e5;
        overflow-x: hidden;
        @include dropDownAnim;

        .item {
            padding: 10px 15px;
            width: 100% !important;

            &:hover {
                background: #f9f9f9;
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
        }
    }
}

// Upload Vidoe important
.upload-video-import {
    min-height: 100vh;
    background: #fff;

    .nav-blk {
        padding: 3em 3em 1.4em 3em;
        width: 20em;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;

        .meta {
            margin-bottom: 4em;
            flex: 1;

            .nam {}

            .sub-txt {
                color: #222222;
                font-weight: 300;
                font-size: 18px;
                padding: 10px 10px 18px 10px;
            }

            .record-btn {
                color: #fff;
                background: $themeColor;
                padding: 12px 34px;
                border-radius: 32px;
                width: 100%;
                text-align: center;
            }
        }

        .nav-ftr {
            width: 100%;

            .copy-rit {
                .lbl {
                    margin-right: 5px;
                }

                .txt {
                    background: #222222;
                    padding: 4px 10px;
                    border-radius: 20px;
                    font-weight: 600;
                    font-size: 13px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .videos-collection {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-left: 30em;
        padding-top: 20px;
        padding-right: 20px;

        .item {
            background: rgba(0, 0, 0, 0.05);
            width: 100%;
            height: 250px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;

            .upper-right {
                top: calc(5% * 3 / 4);
                right: 5%;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.5s;
                z-index: 2;

                .del-btn {
                    background: #000;
                    height: 2vw;
                    width: 2vw;
                    border-radius: 10vw;
                    justify-content: center;

                    .ico {
                        font-size: 1.18vw;
                        color: #fff;
                    }
                }
            }

            .lower-left {
                bottom: calc(5% * 3 / 4);
                left: 5%;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.5s;
                z-index: 2;
            }

            .img {
                object-fit: fill;
                height: 510%;
                width: 102%;
                opacity: 0;
                transition: opacity 0.5s;
                position: absolute;
                top: -1%;
                left: -1%;
                transform: translate(0%, 0%);
                animation: animated-frames 1s steps(4, end) infinite;
            }

            .add-btn {
                position: absolute;
                width: 40%;
                top: 0;
                right: 0;
                bottom: 0;
                left: calc(50% - 20%);

                .ico {
                    height: 70px;
                }
            }

            &.video {
                .img {
                    opacity: 1;
                }

                .upper-right,
                .lower-left {
                    opacity: 1;
                    visibility: visible;
                }

                &::before {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.6%, rgba(0, 0, 0, 0.5));
                    z-index: 1;
                }

                &::after {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: #0003;
                    opacity: 0;
                    transition: all 0.3s;
                    z-index: 1;
                }

                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            .name {
                bottom: calc(13% * 3 / 4);
            }
        }

        .search-bar {
            position: fixed;
            bottom: 3vw;
            right: 3vw;
            background-color: $themeColor;
            border-radius: 3vw;
            width: 6vw;
            height: 6vw;
            overflow: hidden;
            z-index: 3;
            transition: width 0.3s;
            justify-content: flex-end;
            padding: 27px;

            &.active {
                width: 24vw;
            }

            .ico {
                height: 2.6vw;
                color: #fff;
            }

            .iput {
                position: absolute;
                right: 6vw;
                top: 0;
                height: 6vw;
                width: 18vw;
                background: none;
                border: none;
                outline: none;
                color: #fff;
                font-size: 2vw;
                padding: 0vw 0vw 0vw 2.5vw;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.4);
                }
            }
        }
    }
}

// Upload Video Import Record
.upload-video-import-record {
    min-height: 100vh;
    background: #fff;

    .back-btn {
        position: absolute;
        top: 30px;
        left: 40px;

        .ico {
            height: 42px;
        }
    }

    .wrapper {
        justify-content: center;
        padding-top: 56px;

        .hdr-blk {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0px 60px;

            .slogn {
                margin-bottom: 15px;
                text-align: center;
            }

            .sub-title {
                text-align: center;
                margin-bottom: 25px;
            }
        }

        .record-joy-dialog {
            width: 700px;

            .cross-btn {
                display: none;
            }

            .record-container {
                .screen-layout {
                    .video-block {
                        height: 350px;
                        background-color: #e6e6e6;
                    }
                }
            }

            .recorded-blk {
                position: fixed;
                height: 85px;
                background-color: rgba(0, 0, 0, 0.05);

                .wrap {
                    .item {
                        width: 120px;
                    }
                }
            }

            .recording-btn {
                padding: 13px 30px;
                border-radius: 80px;

                .lbl {
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: inherit;
                }
            }

            .preview-container {
                .back-btn {
                    display: none;
                }
            }
        }

        .recorderCountDown {
            position: fixed;
        }
    }

    .hide-file-upload-button {
        position: fixed;
        top: 0;
        left: -1999990px;
    }

    .add-file-btn {
        position: fixed;
        bottom: 3vw;
        right: 3vw;
        background-color: $themeColor;
        border-radius: 3vw;
        width: 6vw;
        height: 6vw;
        overflow: hidden;
        z-index: 3;
        transition: width 0.3s;
        justify-content: center;
        padding: 27px;

        .ico {
            font-size: 3.5vw;
            color: #fff;
        }
    }

    .preview-container {
        .wrap {
            padding: 0px 70px 0px 70px;

            .ftr {
                .but {
                    padding: 10px 30px;
                    border-radius: 50px;
                    background: $themeColor;

                    &.next {
                        margin-left: 14px;
                        border: 1px solid #d6d4f8;
                        background: transparent;

                        .lbl {
                            color: #121212;
                        }
                    }
                }
            }
        }
    }
}

@keyframes animated-frames {
    0% {
        transform: translate(0%, 0%);
    }

    50% {
        transform: translate(0%, -80%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

.uploading-overlay {
    position: fixed;
    inset: 0;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

// circle progress bar
$progress-bar-stroke-width: 3;
$progress-bar-size: 250px;

.circle-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .label {
        position: absolute;
        left: 55%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 44px;
    }

    .circle {
        height: $progress-bar-size;
        transform: rotate(-90deg);
        width: $progress-bar-size;
    }

    .progress-bar__background {
        fill: none;
        stroke: #d3eff1;
        stroke-width: $progress-bar-stroke-width;
    }

    .progress-bar__progress {
        fill: none;
        stroke: #d3eff1;
        stroke: $themeColor;
        stroke-dasharray: 100 100;
        stroke-dashoffset: 100;
        stroke-linecap: round;
        stroke-width: $progress-bar-stroke-width;
        transition: stroke-dashoffset 1s ease-in-out;
    }
}

.dashboard-employee-table {
    .table {
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        margin-top: 20px;
        margin-bottom: 30px;
        overflow: hidden;

        .table-hdr {
            background-color: $themeColor;
            height: 50px;
            padding: 0px 22px;
        }

        .column {
            padding-right: 12px;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            display: flex;
            align-items: center;

            &:last-child {
                flex-grow: 1.5;
            }

            .label {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
            }

            .reactions {
                margin-left: 6px;
                display: flex;
                align-items: center;

                .sms-reaction-icon {
                    position: relative;

                    &:not(:last-child) {
                        margin-right: 6px;
                    }

                    .copied {
                        font-size: 10px;
                        color: #ffffff;
                        background: #3d3c65;
                        position: absolute;
                        top: -25px;
                        width: max-content;
                        padding: 3px 6px;
                        left: -17px;
                        border-radius: 3px;
                        @include anim;
                        @include hide;

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: -3px;
                            left: 41%;
                            height: 7px;
                            width: 7px;
                            background: #3d3c65;
                            transform: rotate(45deg);
                        }
                    }

                    &:hover {
                        .copied {
                            top: -31px;
                            @include show;
                        }
                    }
                }
            }

            &.user-name {
                cursor: pointer;

                .reactions {
                    @include anim(0.2s);
                    @include hide();
                }

                .name {
                    font-size: 14px;
                    @include anim(0.2s);
                }

                &:hover {
                    .reactions {
                        @include show();
                    }

                    .name {
                        font-weight: 600;
                    }
                }
            }
        }

        .table-row {
            padding: 8px 22px;
            width: 100%;

            &:nth-child(even) {
                background-color: #f8f8f8;
            }

            .txt {
                color: #121212;
                font-size: 15px;
            }

            .item {
                display: flex;
                align-items: center;

                .meta {
                    flex: 1;
                }

                .drop-btn {
                    border: none;
                    outline: none;
                    background: none;
                    float: right;
                    color: #333;
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }

            .badges {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .itm {
                    margin: 3px;
                    border-radius: 20px;
                    padding: 5px 10px;
                    background: $themeColor;
                    text-align: center;
                    min-width: fit-content;

                    .num,
                    .lbl {
                        color: #fff;
                        font-size: 13px;
                    }
                }
            }

            .skills {
                flex-wrap: wrap;

                .skill {
                    font-size: 14px;
                    color: $black;
                    background: #e2e2e2;
                    margin: 3px;
                    padding: 5px 7px;
                }
            }

            .profile-img {
                @include bgImage;
                height: 35px;
                width: 35px;
                min-width: 35px;
                margin-right: 7px;
            }

            .cmt {
                font-size: 14px;
                margin-top: 3px;
            }
        }

        .expanded-blk {
            flex: 1;
            padding: 14px 20px;
            justify-content: center;
            text-align: center;
        }
    }
}

.table-navigation {
    padding: 0px 15%;
    justify-content: space-between;

    .prev-btn {
        margin-left: 22px;
        font-weight: 600;
        cursor: pointer;
    }

    .next-btn {
        margin-right: 22px;
        font-weight: 600;
        cursor: pointer;
    }
}

// Reviews Dashboard Page
.reviews-p {
    min-height: 100vh;
    display: flex;
    padding: 20px $horizontalPending;
    align-items: center;
    justify-content: center;

    @include media('<=large') {
        padding: 20px 0px;
    }

    .reviews-p-content {
        width: 100%;

        .reviews-hdr {
            margin-bottom: 2.2rem;

            @include media('<=tablet') {
                margin-bottom: 1.5rem;
            }

            .left {
                flex: 1;

                @include media('<=desktop') {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    width: 100%;

                    @include media('<=desktop') {
                        width: auto;
                        font-size: 18px;
                    }
                }
            }

            .right {
                flex: 1;
                justify-content: flex-end;

                @include media('<=tablet') {
                    flex: inherit;
                }

                .setting-btn {
                    .ico {
                        margin-right: 6px;
                    }

                    .lbl {}
                }
            }
        }

        .MuiBox-root-26,
        .MuiBox-root-32 {
            @include media('<=large') {
                padding: 22px 0px;
            }
        }

        .reviews-p-grid {
            align-items: flex-start;

            @include media('<=desktop') {
                flex-direction: column;
            }

            @include media('<=480px') {
                flex-direction: column;
                align-items: flex-start;
            }

            .label {
                width: 100%;
                text-align: center;
                margin-bottom: 22px;
                font-size: 18px;
                font-weight: 600;

                @include media('<=480px') {
                    text-align: start;
                }
            }

            .left {
                width: 320px;

                @include media('<=desktop') {
                    width: 100%;
                }

                .qr-code-section {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 10px;

                    .qr-code {
                        border: 1px solid $borderColor;
                        padding: 20px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .actions {
                    flex: 1;

                    .action-btn {
                        outline: none;
                        background: transparent;
                        border: 1px solid $borderColor;
                        width: 100%;
                        padding: 9px 12px;
                        border-radius: 3px;
                        color: $lightBlack;
                        height: 43px;

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        &.share-btn {
                            border-color: $themeColor;
                            background: $themeColor;
                            color: #fff;
                        }

                        &:hover {
                            opacity: 0.8;
                        }

                        .txt {
                            width: 100%;
                        }
                    }

                    .copy-btn {
                        background: $themeColor;
                        padding: 6px 8px;
                        min-width: fit-content;
                        border-radius: 4px;

                        .copy-txt {
                            font-size: 12px;
                            color: #fff;
                            margin-right: 3px;
                        }

                        svg {
                            height: 18px;
                        }
                    }
                }
            }

            .right {
                flex: 1;
                width: 100%;
                margin-left: 100px;

                @include media('<=large') {
                    margin-left: 30px;
                }

                @include media('<=desktop') {
                    margin-left: 0px;
                    margin-top: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                &.get-started {
                    margin-left: 40px;

                    @include media('<=large') {
                        margin-left: 30px;
                    }

                    @include media('<=desktop') {
                        margin-left: 0px;
                        margin-top: 14px;
                    }
                }

                .meta {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    .lbl {
                        text-align: start;
                    }

                    .actions {
                        margin-top: 20px;

                        @include media('<=desktop') {
                            width: 100%;
                            align-items: flex-start;
                        }

                        .start-btn {
                            background: $themeColor;
                            color: #fff;
                            height: 41px;
                            width: 140px;
                            border-radius: 5px;
                            margin-right: 12px;
                        }

                        .tutorial-btn {
                            height: 41px;
                            width: 140px;
                            border-radius: 5px;
                            cursor: pointer;
                            overflow: hidden;

                            .itm {
                                position: absolute;
                                inset: 0;
                                background-color: rgba(0, 0, 0, 0.3);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                z-index: 2;

                                .txt {
                                    font-size: 15px;
                                    font-weight: 500;
                                    color: #fff;
                                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
                                }
                            }
                        }
                    }
                }

                .badges {
                    justify-content: center;
                    flex: 1;

                    .itm {
                        flex-direction: row;
                        background: rgba(109, 163, 235, 0.07);
                        padding: 7px 18px;
                        border-radius: 16px;

                        &:not(:last-child) {
                            margin-right: 12px;
                        }

                        .num,
                        .lbl {
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .lbl {
                            margin-left: 4px;
                        }
                    }
                }

                .reviews-section {
                    align-items: flex-start;
                    justify-self: flex-start;

                    @include media('<=desktop') {
                        width: 100%;
                    }

                    .reviews-title {
                        font-size: 48px;
                        font-weight: 300;
                        letter-spacing: 0.3px;

                        .qty {
                            font-weight: 600;
                        }
                    }

                    .meta {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .star-blk {
                            padding: 6px 14px 4px 14px;
                            background: rgba(109, 163, 235, 0.07);
                            border-radius: 100px;
                            margin-bottom: 20px;

                            .num {
                                font-size: 13px;
                                color: #555;
                                margin-left: 5px;
                                margin-bottom: 3px;
                            }

                            .MuiRating-decimal {
                                line-height: 1;

                                &.MuiRating-iconActive {
                                    color: $themeColor !important;

                                    svg {
                                        fill: $themeColor !important;
                                    }
                                }
                            }
                        }

                        .goal-block {
                            .icon {
                                margin-right: 8px;

                                svg {
                                    height: 32px;
                                    color: #1766af;
                                }
                            }

                            .btt {
                                cursor: pointer;
                            }

                            .lbl {
                                color: #1766af;
                            }

                            .edit-btn {
                                margin-bottom: 6px;
                                margin-left: 12px;
                                font-size: 13px;
                            }
                        }
                    }
                }

                .static-feedbacks {
                    margin-top: 24px;

                    .tit {
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 13px;
                        margin-bottom: 7px;
                    }

                    .feeds {
                        flex-wrap: wrap;

                        .feed-item {
                            font-size: 14px;
                            background: #f6f6f6;
                            padding: 8px 16px;
                            border-radius: 3px;
                            margin: 5px;
                        }
                    }
                }

                .rating-blk {
                    margin-top: 20px;

                    .lbl {
                        margin-bottom: 6px;
                    }
                }
            }
        }

        .staff-feedbacks {
            padding: 20px 0px;
            margin-top: 20px;

            @include media('<=480px') {
                margin-top: 10px;
            }

            .hdr {
                margin-bottom: 18px;

                .left {
                    flex: 1;
                }

                .right {
                    flex: 1;
                    justify-content: flex-end;

                    .badges {
                        .itm {
                            flex-direction: row;
                            background: rgba(109, 163, 235, 0.07);
                            padding: 7px 18px;
                            border-radius: 16px;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }

                            .num,
                            .lbl {
                                font-size: 14px;
                                font-weight: 400;
                            }

                            .lbl {
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }

            .search-blk {
                margin-top: 10px;
                margin-bottom: 24px;

                .left {
                    flex: 1;

                    .srchbar {
                        background: rgba(109, 163, 235, 0.07);
                        padding: 0px 16px;
                        width: 300px;
                        border-radius: 2px;
                        overflow: hidden;

                        @include media('<=480px') {
                            width: 100%;
                            margin-right: 10px;
                        }

                        .ico {
                            margin-right: 12px;
                        }

                        .iput {
                            padding: 10px 0px;
                            width: 100%;
                        }
                    }
                }

                .right {
                    flex: 1;
                    justify-content: flex-end;

                    .add-btn {
                        .ico {
                            margin-right: 4px;
                        }
                    }
                }
            }

            .employees-list-blk {
                width: 100%;

                .wrapper {
                    .employee {
                        padding: 8px 20px;
                        margin-bottom: 7px;
                        background: rgba(56, 152, 236, 0.05);
                        cursor: pointer;
                        height: 48px;

                        &:nth-child(even) {
                            background: rgba(109, 163, 235, 0.045);
                        }

                        &:hover {
                            color: $black;
                        }

                        &.holder {
                            height: 40px;
                            width: 100%;
                            background: #dddd;
                        }

                        .lit {
                            flex: 1;

                            .name {}
                        }

                        .rit {
                            .action-btn {
                                font-size: 20px;
                                height: 28px;
                                width: 28px;
                                background: #f8f8f8;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 22px;
                                color: #333;
                                @include anim;
                                @include hide;

                                &:not(:last-child) {
                                    margin-right: 12px;
                                }
                            }
                        }

                        &:hover {
                            .rit {
                                .action-btn {
                                    @include show;
                                }
                            }
                        }
                    }
                }
            }

            .empty-employees {
                width: 100%;
                justify-content: center;
                align-items: center;
                padding: 30px;

                .ico {
                    font-size: 52px;
                    margin-bottom: 12px;
                }

                .lbl {}
            }
        }
    }

    .create-reviews-sections {
        @include media('<=tablet') {
            flex-direction: column-reverse;
        }

        .left {
            flex: 1;
            padding-right: 80px;

            @include media('<=tablet') {
                padding-right: 0px;
            }

            .step-blk {
                margin-top: 25px;

                &.first {
                    margin-bottom: 45px;
                }

                .meta {
                    margin-bottom: 12px;

                    .step-no {
                        height: 40px;
                        width: 40px;
                        min-width: 40px;
                        border-radius: 50%;
                        background: #f5f5f5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                    }

                    .txt {
                        font-size: 14px;
                        line-height: 1.4;
                    }
                }

                .msg-blk {
                    width: 88%;

                    @include media('<=tablet') {
                        width: 90%;
                    }

                    .msg {
                        width: 100%;
                        height: 100%;
                        color: white;
                        padding: 12px 20px;
                        border-radius: 16px;
                        border: none;
                        resize: none;
                        position: relative;
                        word-wrap: break-word;
                        background-color: $themeColor;

                        &::before {
                            content: '';
                            position: absolute;
                            z-index: 0;
                            bottom: 0;
                            right: -8px;
                            height: 20px;
                            width: 20px;
                            background: linear-gradient(to bottom, $themeColor 0%, $themeColor 100%);
                            background-attachment: fixed;
                            border-bottom-left-radius: 15px;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            z-index: 1;
                            bottom: 0;
                            right: -10px;
                            width: 10px;
                            height: 20px;
                            background: white;
                            border-bottom-left-radius: 10px;
                        }

                        .lin {
                            margin-top: 10px;
                        }
                    }

                    .user {
                        position: absolute;
                        bottom: -48px;
                        right: -42px;
                        z-index: 2;

                        .name {
                            margin-right: 10px;
                            margin-top: 8px;
                        }

                        .img {
                            @include bgImage;
                            height: 65px;
                            width: 65px;
                            min-width: 65px;
                        }
                    }
                }

                .google-review {
                    @include bgImage(10px);
                    height: 200px;
                    width: 86%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                    padding: 5px;

                    @include media('<=tablet') {
                        width: 100%;
                    }

                    .meta {
                        width: 80%;
                        text-align: end;

                        .nam {
                            line-height: 1;
                            margin-bottom: 3px;
                        }
                    }
                }

                .feedback-blk {
                    border: 1px solid #b5bfcd;
                    padding: 14px;
                    border-radius: 5px;

                    .lit {
                        flex: 1.2;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        padding-right: 24px;
                        text-align: end;
                    }

                    .rit {
                        flex: 1;

                        .itm {
                            width: 100%;
                            padding: 0px 20px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:first-child {
                                background-color: $themeColor;
                            }

                            &:nth-child(even) {
                                background: #f8f8f8;
                            }
                        }
                    }
                }
            }
        }

        .right {
            flex: 1.1;
            border-left: 1px solid #ddd;
            padding: 40px 80px;

            @include media('<=tablet') {
                border-left: 0px;
                padding: 10px 0px 40px 0px;
            }

            .meta {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                .actions {
                    margin-top: 20px;

                    .start-btn {
                        background: $themeColor;
                        color: #fff;
                        height: 41px;
                        width: 140px;
                        border-radius: 5px;
                        margin-right: 12px;
                    }

                    .tutorial-btn {
                        height: 41px;
                        width: 140px;
                        border-radius: 5px;
                        cursor: pointer;
                        overflow: hidden;

                        .itm {
                            position: absolute;
                            inset: 0;
                            background-color: rgba(0, 0, 0, 0.3);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 2;

                            .txt {
                                font-size: 15px;
                                font-weight: 500;
                                color: #fff;
                                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
                            }
                        }
                    }
                }
            }

            .roi-blk {
                margin-top: 60px;

                @include media('<=tablet') {
                    justify-content: center;
                    align-items: center;
                    margin-top: 60px;
                }

                .lbl {
                    margin-bottom: 7px;
                    text-transform: uppercase;
                }

                .itm {
                    width: 260px;
                    margin-bottom: 20px;

                    .number {
                        .tag {
                            line-height: 1.2;
                        }

                        .num {
                            line-height: 1;
                        }
                    }

                    .tit {
                        margin-left: 12px;
                        line-height: 1.3;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .review-page-loading {
        justify-content: center;
        padding: 10px 0px;
    }
}

// List of All Reviews
.all-user-reviews {
    margin-top: 30px;

    .hdr {
        width: 100%;

        .ls {
            flex: 1;

            .tit {
                font-size: 22px;
                line-height: 1;
                font-weight: 600;
            }
        }

        .rs {
            flex: 1;
        }
    }

    .reviews-list {}
}

.edit-employee-dialog {
    .review-dashboard-staff-form {
        width: 100%;
    }
}

// goal-reviews-dialog
.goal-reviews-dialog {
    width: 100%;

    .hdr {
        padding: 8px 20px;
        border-bottom: 1px solid #ddd;
        height: 60px;

        .title {
            flex: 1;
            color: $black;
        }

        .cross-btn {
            font-size: 30px;
            color: $black;
            height: 32px;
            width: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .wrap {
        margin: 20px 20px;

        .iput {
            outline: none;
            background: none;
            width: 100%;
            border: 1px solid #ddd;
            padding: 11px 20px;
            border-radius: 3px;
            @include anim;
            margin-bottom: 18px;

            &:focus {
                border-color: $themeColor;
            }
        }
    }

    .ftr {
        padding: 20px 0px 16px 0px;

        .add-cata {
            background: $themeColor;
            width: 100%;
            padding: 10px 20px;
            border-radius: 3px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.cancel {
                background: transparent;
                border: 1px solid #ddd;
            }
        }
    }
}

.dashboard-settings-buttons {
    padding: 20px;
    width: 280px;

    .link-btn {
        background: $themeColor;
        width: 100%;
        padding: 10px 20px;
        border-radius: 3px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

// Reviews Dashboard Setting
.reviews-dashboard-settings {
    padding: 20px $horizontalPending;

    @include media('<=large') {
        padding: 20px 0px;
    }

    .reviews-loader {
        justify-content: center;
        z-index: 99;
        background: rgba(255, 255, 255, 0.8);
        @include anim;

        .sho {
            @include show;
        }

        .hid {
            @include hide;
        }
    }

    .section {
        .hdr {
            margin-bottom: 22px;

            .title {
                flex: 1;
            }

            .back-btn {
                &:hover {
                    text-decoration: underline;
                }

                .ico {
                    margin-right: 4px;
                }
            }
        }

        .table {
            .field {
                margin-bottom: 20px;

                .lbl {
                    margin-bottom: 5px;
                    font-size: 14px;
                    color: $black;
                    font-weight: 500;
                }

                .iput {
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    outline: none;
                    background: rgba(0, 0, 0, 0.03);
                    color: $black;
                    font-size: 15px;
                    padding: 10px 18px;
                    border-radius: 5px;
                    font-weight: 400;
                }

                .put-badge {
                    padding: 0px 5px;

                    input {
                        flex: 1;
                        padding: 10px 13px;
                        background: transparent;
                        border: none;
                        outline: none;
                    }

                    .add-badge {
                        font-size: 14px;
                        color: #fff;
                        background: $themeColor;
                        width: fit-content;
                        border-radius: 3px;
                        padding: 8px 12px;

                        &:disabled {
                            opacity: 0.9;
                            cursor: no-drop;
                        }
                    }
                }
            }

            .badge-block {
                width: 100%;
                margin-bottom: 10px;
                margin-top: 3px;

                .badge-itm {
                    background: $themeColor;
                    padding: 1px 12px;
                    border-radius: 28px;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }

                    .badge-btn {
                        font-size: 18px;
                        margin-left: 6px;
                        margin-bottom: 2px;
                    }
                }
            }

            .actions {
                width: 100%;
                margin-top: 12px;

                .form-btn {
                    min-width: 150px;
                    max-width: fit-content;
                    font-size: 14px;
                    border: 1px solid $themeColor;
                    border-radius: 3px;
                    background: $themeColor;
                    color: #fff;
                    padding: 10px 20px;
                    margin-top: 10px;

                    @include media('<=480px') {
                        min-width: 100%;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    &.cancel {
                        background: transparent;
                        color: #121212;
                    }

                    &.delete {
                        border-color: $red;
                        color: $red;
                        background: transparent;
                    }
                }
            }
        }
    }
}

.share-link-dialog {
    .cross-btn {
        position: absolute;
        top: 10px;
        right: 12px;
        font-size: 24px;
    }

    .sms-container {
        padding: 25px 25px;

        .left {
            flex: 1;
            margin-bottom: 30px;

            .label {
                margin-bottom: 8px;
            }

            .msg {
                width: 100%;
                height: 100%;
                color: white;
                padding: 12px 20px;
                border-radius: 12px;
                border: none;
                resize: none;
                position: relative;
                word-wrap: break-word;

                &.sms {
                    background-color: $themeColor;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        bottom: 0;
                        right: -8px;
                        height: 20px;
                        width: 20px;
                        background: linear-gradient(to bottom, $themeColor 0%, $themeColor 100%);
                        background-attachment: fixed;
                        border-bottom-left-radius: 15px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        right: -10px;
                        width: 10px;
                        height: 20px;
                        background: white;
                        border-bottom-left-radius: 10px;
                    }
                }

                &.email {
                    background-color: #f1f5f8;
                    color: $lightBlack;
                }
            }
        }

        .right {
            flex: 1;

            .form {
                .field {
                    margin-bottom: 15px;

                    .item {
                        &:not(:last-child) {
                            margin-right: 40px;
                        }

                        .ico {
                            height: 22px;
                            width: 22px;
                            min-width: 22px;
                            border-radius: 3px;
                            border: 1px solid $borderColor;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 15px;
                            @include anim;

                            &.on {
                                background: $themeColor;
                                color: #fff;
                                border-color: $themeColor;
                            }
                        }

                        .lbl {
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: 500;
                            color: $black;
                        }
                    }
                }

                .iput {
                    padding: 9px 20px;
                    border: 1px solid $borderColor;
                    border-radius: 3px;
                    margin-bottom: 12px;
                    font-size: 15px;

                    &:focus {
                        border-color: $themeColor;
                    }
                }

                .actions {
                    justify-content: space-between;
                    margin-top: 5px;

                    .request-btn {
                        outline: none;
                        border: 1px solid $themeColor;
                        padding: 8px 22px;
                        background: $themeColor;
                        color: #fff;
                        font-size: 15px;
                        border-radius: 4px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .send-mail-btn {
                        outline: none;
                        border: none;
                        background: transparent;
                        color: $themeColor;
                    }
                }
            }
        }
    }
}

// Review Dashboard Staff Form
.review-dashboard-staff-form {
    width: 380px;
    padding: 22px 26px;

    .title {
        text-align: center;
        margin-bottom: 20px;
    }

    .form {
        .iput {
            font-size: 15px;
            color: #333;
            width: 100%;
            border: 1px solid #ddd;
            padding: 10px 20px;
            border-radius: 3px;
            @include anim;

            &:focus {
                border-color: $themeColor;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .actions {
            width: 100%;

            .form-btn {
                flex: 1;
                font-size: 14px;
                border: 1px solid #ddd;
                border-radius: 3px;
                background: $themeColor;
                color: #fff;
                padding: 9px 20px;
                margin-top: 10px;

                &:hover {
                    opacity: 0.8;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &.cancel {
                    background: transparent;
                    color: #121212;
                }
            }
        }
    }
}

// Review Dashboard employee page
.review-dashboard-employee-p {
    padding: 20px $horizontalPending;

    @include media('<=large') {
        padding: 20px 0px;
    }

    .review-page-loading {
        justify-content: center;
        padding: 10px 0px;
        min-height: 86vh;
    }

    .employee-hdr {
        width: 100%;
        margin-bottom: 15px;

        .back-btn {
            .ico {
                margin-right: 5px;
            }
        }
    }

    .employe-nam {
        margin-bottom: 18px;
        justify-content: space-between;

        @include media('<=phoneH') {
            flex-direction: column;
        }

        .title {
            @include media('<=phoneH') {
                margin-bottom: 4px;
            }
        }

        .MuiRating-decimal {
            &.MuiRating-iconActive {
                color: $themeColor !important;

                svg {
                    fill: $themeColor !important;
                }
            }
        }

        .star-blk {
            .icons {
                line-height: 1;
            }
        }

        .total-generated {}
    }

    .sms-container {
        border: 1px solid $borderColor;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 40px 40px;

        @include media('<=large') {
            padding: 22px 22px;
        }

        @include media('<=tablet') {
            flex-direction: column;
        }

        .left {
            flex: 1;
            margin-right: 60px;

            @include media('<=large') {
                margin-right: 30px;
            }

            @include media('<=tablet') {
                margin-right: 0px;
            }

            .label {
                margin-bottom: 8px;
            }

            .msg {
                width: 100%;
                height: 100%;
                color: white;
                padding: 12px 20px;
                border-radius: 16px;
                border: none;
                resize: none;
                position: relative;
                word-wrap: break-word;

                &.sms {
                    background-color: $themeColor;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        bottom: 0;
                        right: -8px;
                        height: 20px;
                        width: 20px;
                        background: linear-gradient(to bottom, $themeColor 0%, $themeColor 100%);
                        background-attachment: fixed;
                        border-bottom-left-radius: 15px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        right: -10px;
                        width: 10px;
                        height: 20px;
                        background: white;
                        border-bottom-left-radius: 10px;
                    }
                }

                &.email {
                    background-color: #f1f5f8;
                    color: $lightBlack;
                }
            }
        }

        .right {
            flex: 1;

            @include media('<=tablet') {
                width: 100%;
                margin-top: 28px;
            }

            .form {
                .field {
                    margin-bottom: 15px;

                    .item {
                        &:not(:last-child) {
                            margin-right: 40px;
                        }

                        .ico {
                            height: 22px;
                            width: 22px;
                            min-width: 22px;
                            border-radius: 3px;
                            border: 1px solid $borderColor;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 15px;
                            @include anim;

                            &.on {
                                background: $themeColor;
                                color: #fff;
                                border-color: $themeColor;
                            }
                        }

                        .lbl {
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: 500;
                            color: $black;
                        }
                    }
                }

                .iput {
                    padding: 9px 20px;
                    border: 1px solid $borderColor;
                    border-radius: 3px;
                    margin-bottom: 12px;
                    font-size: 15px;

                    &:focus {
                        border-color: $themeColor;
                    }
                }

                .actions {
                    justify-content: space-between;
                    margin-top: 5px;

                    .request-btn {
                        outline: none;
                        border: 1px solid $themeColor;
                        padding: 8px 22px;
                        background: $themeColor;
                        color: #fff;
                        font-size: 15px;
                        border-radius: 4px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .send-mail-btn {
                        outline: none;
                        border: none;
                        background: transparent;
                        color: $themeColor;
                    }
                }
            }
        }
    }

    .feedback-table {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0px;
        }

        .expanded-comments {
            padding: 24px 24px 11px 24px;
        }

        &.pending-table {
            .table-hdr {
                background-color: #a9a9a9;
            }
        }
    }
}

.no-employees {
    justify-content: center;
}

.loading-overlay {
    position: fixed;
    inset: 0;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9;
}

// Survey page
.survey-page {
    .survey-banner {
        @include bgImage(0px);
        height: 35vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex: 1;
        padding: 25px 5%;

        .community-name {
            line-height: 1.4;
        }
    }

    .wrapper {
        margin: 0px 5%;
        padding: 25px 0px 40px 0px;
        flex: 1;

        .hdr {
            justify-content: flex-end;
            flex: 1;
            margin-bottom: 12px;

            .back-btn {
                &:hover {
                    text-decoration: underline;
                }

                &:disabled {
                    text-decoration: none;
                    cursor: no-drop;
                }
            }
        }
    }

    .question-text {
        margin-bottom: 22px;
    }

    .answer-choice {
        display: flex;
        align-items: center;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 25px;
        height: 44px;
        text-align: left;
        font-weight: 600;
        background-color: #efefef;
        -webkit-transition-duration: 0.5s;
        /* Safari */
        transition-duration: 0.5s;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        .letter-container {
            width: 35px;
            min-width: 35px;
            height: 35px;
            border-style: none;
            border-color: #3175b1;
            border-radius: 50px;
            background-color: #3175b1;
            margin-right: 8px;
            margin-left: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .letter {
            text-decoration: none;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .review-container {
        margin: 4% 0% 4% 0%;

        .blk {
            margin-bottom: 10px;

            .label {
                margin-bottom: 10px;
            }

            .rating-star {
                justify-content: center;
                line-height: 1;

                .MuiRating-sizeLarge {
                    font-size: 4rem;
                }
            }

            .iput {
                width: 100%;
                background: #f1f1f1;
                resize: none;
                border: 1px solid transparent;
                @include anim;
                padding: 12px 20px;
                margin-bottom: 20px;

                &.area {
                    height: 200px;
                    width: 80%;
                }

                &:focus {
                    border-color: #342e2e;
                    background: transparent;
                }
            }
        }

        .continue-btn {
            width: 80%;
            height: 56px;
            margin: 10px 0px 0px 0px;
            border-radius: 8px;
            border: none;
            font-size: 26px;
            background-color: #3175b1;
            color: white;
            font-weight: 700;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            justify-content: center;
        }
    }
}

.badges form {
    display: inline-block;
    margin-bottom: 10px;
}

.badges input[type='checkbox'] {
    display: none;
}

.badges label {
    margin: 0px 10px 12px 0px;
    border-radius: 50px;
    padding: 8px 18px;
    font-weight: 500;
    background-color: #e9e9e9;
    text-align: center;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
}

.badges label span {
    margin-right: 5px;
}

.badges label span::after {
    content: '+';
}

.badges input[type='checkbox']:checked+label {
    background-color: #3175b1;
    color: white;
    filter: none;
}

.badges input[type='checkbox']:checked+label span::after {
    content: '✓';
}

// Survey Thanks you Page
.survey-thanks-page {
    .wrapper {
        justify-content: center;
        text-align: center;
        padding: 40px 0px;

        .meta {
            margin-bottom: 60px;
        }

        .block {
            width: 68%;

            .loading {
                margin-bottom: 12px;
            }

            .msg {
                margin-bottom: 16px;
            }

            .actions-btns {
                width: 100%;

                .action {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    &:not(:last-child) {
                        margin-right: 15px;
                    }

                    .actions-btn {
                        outline: none;
                        background: #e2e2e2;
                        padding: 10px;
                        border: 1px solid transparent;
                        text-transform: uppercase;
                        font-weight: 500;

                        &.confirm-btn {
                            background: #342e2e;
                            color: #fff;
                        }
                    }

                    .txt {
                        font-size: 14px;
                        font-weight: 500;
                        color: $lightBlack;
                        margin-top: 10px;
                    }
                }
            }
        }

        .confirm-msg {
            margin-top: 22px;
        }
    }
}

.survey-thanks-bux-dialog {
    width: 100%;
    height: 520px;
    overflow: hidden;

    .close-btn {
        position: absolute;
        top: 5px;
        right: 13px;
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        color: #333;
    }

    .left {
        flex: 1;
        @include bgImage(0px);
        position: relative;

        .left-content {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.7);
            z-index: 9;
            padding: 24px 26px;

            .title {
                font-size: 20px;
                font-weight: 600;
                color: #fff;
            }

            .txt {
                font-size: 14px;
                color: #fff;
                margin-top: 5px;
                line-height: 1.4;
            }

            .note {
                padding: 7px 15px;
                border: 1px solid #fff;
                font-size: 14px;
                color: #fff;
                margin-top: 22px;
                line-height: 1.4;
            }
        }
    }

    .right {
        flex: 1;
        padding: 36px 30px 22px 30px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        .form {
            .user-blk {
                width: 100%;
                margin-bottom: 5px;
                display: flex;

                .dp {
                    @include bgImage(3px);
                    overflow: hidden;
                    margin-right: 12px;
                    height: 80px;
                    width: 80px;
                    min-width: 80px;
                }

                .meta {
                    width: 100%;

                    .iput {
                        margin-bottom: 8px;
                    }
                }
            }

            .tag-blk {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                .tag-blk-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: $black;
                }

                .selected-tags {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-left: -5px;

                    .tag {
                        font-size: 13px;
                        color: $black;
                        background: #f5f5f5;
                        padding: 6px 12px;
                        border-radius: 20px;
                        margin: 4px;
                    }
                }

                .tag-selector {
                    margin-top: 11px;
                    position: relative;

                    .input-text {
                        width: 100%;
                        font-size: 14px;
                        color: $black;
                    }

                    .add-btn {
                        font-size: 14px;
                        color: $black;
                        cursor: pointer;
                    }
                }
            }

            .tag-list {
                background: #ffffff;
                box-shadow: 0px 8px 9px rgba(0, 0, 0, 0.12);
                width: 100%;
                display: flex;
                flex-direction: column;
                position: sticky;
                left: 0px;
                right: 0px;
                padding: 5px 0px;
                display: none;

                &.active {
                    display: flex;
                    @include dropDownAnim(0.2s);
                }

                .item {
                    font-size: 14px;
                    color: $black;
                    padding: 6px 20px;
                    width: 100%;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background: #f8f8f8;
                    }
                }
            }

            .iput {
                width: 100%;
                height: 44px;
                outline: none;
                border: 1px solid transparent;
                padding: 9px 18px;
                @include anim;
                background: #f1f1f1;
                font-size: 14px;
                color: $black;

                &:focus {
                    border-color: #342e2e;
                    background: transparent;
                }

                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                &.area {
                    height: 80px;
                    resize: none;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                }
            }

            .submit-btn {
                background: #342e2e;
                width: 100%;
                padding: 9px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 600;
            }
        }

        .notic {
            margin-top: 15px;
        }
    }

    &.referral-blk {
        .right {
            .form {
                .tag-blk {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

// Rewards page
.rewards-page {
    .hdr {
        width: 100%;
        padding: 7px $horizontalPending;

        .left {
            .page-title {}

            .balance {
                background: $themeColor;
                padding: 0px 8px;
                border-radius: 20px;
                margin-left: 10px;
            }
        }

        .right {
            flex: 1;
            justify-content: flex-end;

            .add-credits-btn {
                border: 1px solid $themeColor;
                padding: 9px 14px;
                text-transform: uppercase;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .wrapper {
        padding: 22px $horizontalPending;

        @include media('<=large') {
            padding: 22px 0px;
        }

        flex: 1.5;

        .block {
            margin-bottom: 32px;

            .title {
                margin-bottom: 12px;
                color: $black;
                font-weight: 600;
                font-size: 16px;
            }

            .templates {
                width: 100%;
                display: flex;
                align-items: center;
                overflow: hidden;

                .template-blk {
                    height: 320px;
                    width: 95%;
                    border: 1px solid $borderColor;
                    background: #f8fafc;
                    @include anim;
                    border-radius: 8px;
                    overflow: hidden;

                    @include media('<=desktop') {
                        width: 97%;
                    }

                    .graphic {
                        @include bgImage(0px);
                        height: 70%;
                        background-color: #e9e9e9;
                    }

                    .ftr {
                        display: flex;
                        height: 30%;
                        padding: 11px;
                        border-top: 1px solid $borderColor;
                        @include anim;

                        @include media('<=480px') {
                            padding: 10px 8px;
                        }

                        .dollar-sign {
                            margin-right: 10px;
                            background: $themeColor;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: fit-content;
                            padding: 0px 9px;
                            border-radius: 12px;

                            @include media('<=480px') {
                                font-size: 12px;
                                padding: 0px 7px;
                                height: 22px;
                                border-radius: 50%;
                                margin-right: 5px;
                            }
                        }

                        .meta {
                            .lbl {
                                margin-bottom: 2px;
                            }

                            .disc {
                                font-size: 13px;
                                font-weight: 300;
                            }
                        }
                    }

                    &.active,
                    &:hover {
                        border-color: $themeColor;
                    }
                }
            }

            .gifts-blk {
                width: 100%;
                overflow: hidden;

                .gift-item {
                    border: 1px solid $lightBlack;
                    padding: 8px 12px;
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    text-align: center;
                    overflow: hidden;
                    font-weight: 600;
                    color: $lightBlack;
                    font-size: 16px;
                    @include anim;
                    height: 43px;

                    .sign {
                        margin-right: 5px;
                    }

                    .iput {
                        width: 100%;
                        text-align: center;
                    }

                    .sign,
                    .iput {
                        font-weight: 600;
                        color: $lightBlack;
                        font-size: 16px;
                    }

                    &.active {
                        border-color: $themeColor;
                        background: rgba(56, 152, 236, 0.15);
                        color: $themeColor;
                    }
                }
            }
        }

        .form {
            width: 100%;

            .field {
                margin-top: 10px;

                .tit {
                    margin-bottom: 6px;
                }

                .blk {
                    @include media('<=480px') {
                        flex-direction: column;
                    }

                    .iput {
                        border: 1px solid $lightBlack;
                        padding: 9px 12px;
                        width: 100%;
                        border-radius: 4px;
                        font-weight: 500;
                        color: $lightBlack;

                        &:not(:last-child) {
                            margin-right: 12px;
                        }

                        @include media('<=480px') {
                            margin-right: 0px;
                            margin-bottom: 18px;
                            padding: 10px 12px;
                        }

                        @include anim;

                        &:focus {
                            border-color: $themeColor;
                        }
                    }
                }
            }
        }

        .mail-template-form {
            padding: 20px;
            border: 1px solid $lightBlack;
            border-radius: 5px;
            width: 100%;
            margin-bottom: 32px;

            @include media('<=tablet') {
                width: 100%;
            }

            .hdr {
                border-bottom: 1px solid $lightBlack;
                padding-bottom: 20px;

                .field {
                    margin-bottom: 10px;

                    .lbl {
                        width: 80px;
                    }

                    .iput {
                        width: 100%;
                        outline: none;
                        border: none;
                        cursor: revert;

                        &.area {
                            overflow: hidden;
                            resize: none;
                            cursor: revert;
                        }
                    }

                    &.from {
                        .lbl {
                            color: rgba(0, 0, 0, 0.27);
                        }

                        .iput {
                            color: rgba(0, 0, 0, 0.27);

                            &::placeholder {
                                color: rgba(0, 0, 0, 0.27);
                            }
                        }
                    }
                }
            }

            .amount {
                background: $black;
                padding: 3px 24px;
                border-radius: 7px;
                width: fit-content;
                margin: 30px 0px;
            }

            .message {
                margin: 22px 0px;
                resize: none;
            }

            .your-gift {
                .lbl {
                    margin-bottom: 12px;
                }

                .gift-image {
                    @include bgImage(10px);
                    height: 280px;
                    background-color: #f1f5f8;

                    @include media('<=480px') {
                        height: 220px;
                    }
                }
            }
        }

        .send-btn {
            width: 140px;
            padding: 11px 18px;
            border-radius: 2px;
            background: $themeColor;

            &:hover {
                opacity: 0.85;
            }

            .ico {
                margin-left: 20px;
            }

            @include media('<=480px') {
                width: 100%;
            }
        }

        .forms-section {
            align-items: flex-start;

            @include media('<=large') {
                flex-direction: column;
                width: 100%;
            }
        }

        .gift-side {
            flex: 1;
            max-width: 500px;

            @include media('<=large') {
                flex-direction: column;
                max-width: 100%;
                width: 100%;
                margin-bottom: 32px;
            }
        }

        .reward-request-side {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            margin-left: 30px;

            @include media('<=large') {
                width: 100%;
                margin-left: 0px;
            }

            .hdr {
                width: 100%;

                .title {
                    color: $black;
                    font-weight: 600;
                    font-size: 16px;
                    flex: 1;
                    line-height: 1;
                }

                .actions {
                    cursor: pointer;

                    .action {
                        &:not(:last-child) {
                            margin-right: 22px;
                        }

                        &.on {
                            .checkbox {
                                border-color: $themeColor;

                                &::before {
                                    @include show;
                                }
                            }
                        }

                        .checkbox {
                            height: 18px;
                            width: 18px;
                            min-width: 18px;
                            border-radius: 100px;
                            border: 1px solid $lightBlack;
                            position: relative;
                            @include anim(0.15s);
                            margin-right: 8px;

                            &::before {
                                content: '';
                                height: 13px;
                                width: 13px;
                                border-radius: 100px;
                                background: $themeColor;
                                position: absolute;
                                left: 1.5px;
                                top: 1.5px;
                                @include hide;
                                @include anim(0.15s);
                            }
                        }

                        .lbl {
                            font-size: 13px;
                            font-weight: 500;
                            color: $black;
                        }
                    }
                }
            }

            .reward-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .reward-table {
                    width: 100%;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .table {
                        width: 100%;
                        border-radius: 4px;
                        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
                        margin-top: 20px;
                        margin-bottom: 30px;
                        overflow: hidden;

                        .table-hdr {
                            background-color: $themeColor;
                            height: 50px;
                            padding: 0px 22px;

                            &.Pending {
                                background-color: #767676;
                            }
                        }

                        .column {
                            padding-right: 12px;
                            flex-basis: 0;
                            flex-grow: 1;
                            max-width: 100%;

                            &:last-child {
                                flex-grow: 1.5;
                            }

                            .label {
                                font-size: 16px;
                                font-weight: 600;
                                color: #fff;
                            }
                        }

                        .table-row {
                            padding: 8px 22px;
                            width: 100%;

                            &:nth-child(even) {
                                background-color: #f8f8f8;
                            }

                            .txt {
                                color: #121212;
                                font-size: 15px;
                            }

                            .item {
                                display: flex;
                                align-items: center;

                                .meta {
                                    flex: 1;
                                }

                                .drop-btn {
                                    border: none;
                                    outline: none;
                                    background: none;
                                    float: right;
                                    color: #333;
                                    flex: 1;
                                    display: flex;
                                    align-items: flex-end;
                                    justify-content: flex-end;
                                }
                            }

                            .badges {
                                flex: 1;
                                display: flex;
                                align-items: center;

                                .itm {
                                    margin-right: 5px;
                                    border-radius: 20px;
                                    padding: 5px 12px;
                                    background: $themeColor;
                                    text-align: center;

                                    .num,
                                    .lbl {
                                        color: #fff;
                                        font-size: 14px;
                                        font-weight: 500;
                                    }
                                }
                            }

                            .cmt {
                                font-size: 14px;
                                margin-top: 3px;
                            }
                        }

                        .expanded-blk {
                            flex: 1;
                            padding: 14px 20px;
                            justify-content: center;
                            text-align: center;
                        }
                    }

                    .table-navigation {
                        padding: 0px 15%;
                        justify-content: space-between;

                        .prev-btn {
                            margin-left: 22px;
                        }

                        .next-btn {
                            margin-right: 22px;
                        }
                    }

                    .expanded-comments {
                        padding: 24px 24px 11px 24px;
                    }

                    &.pending-table {
                        .table-hdr {
                            background-color: #a9a9a9;
                        }
                    }

                    .expanded-reward-request {
                        flex: 1;
                        padding: 14px 20px;

                        .left {
                            width: 100%;

                            .left-wrap {
                                width: 100%;

                                .to-side {
                                    flex: 1;
                                    margin-right: 20px;
                                }

                                .from-side {
                                    flex: 1;
                                }

                                .tit {
                                    font-size: 15px;
                                    font-weight: 600;
                                    color: $black;
                                    line-height: 1;
                                }
                            }

                            .field {
                                width: 100%;
                                margin-top: 13px;

                                .iput {
                                    outline: none;
                                    border: none;
                                    background: transparent;
                                    width: 100%;
                                    border-bottom: 1px solid #ddd;
                                    font-size: 14px;
                                    color: $black;

                                    &.area {
                                        resize: none;
                                        height: 80px;
                                        border: 1px solid #ddd;
                                        padding: 12px;
                                        border-radius: 3px;
                                        text-align: start;
                                        margin-top: 8px;
                                    }
                                }
                            }
                        }

                        .actions {
                            margin-top: 8px;

                            .action {
                                outline: none;
                                border: none;
                                background: transparent;
                                background: $themeColor;
                                color: #fff;
                                padding: 9px 14px;
                                font-size: 13px;
                                font-weight: 500;
                                border-radius: 3px;
                                min-width: 100px;

                                @include media('<=desktop') {
                                    min-width: 80px;
                                }

                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }

                .expanded-reward-request {
                    flex: 1;
                    padding: 14px 20px;

                    .left {
                        width: 100%;

                        .left-wrap {
                            width: 100%;

                            .to-side {
                                flex: 1;
                                margin-right: 20px;
                            }

                            .from-side {
                                flex: 1;
                            }

                            .tit {
                                font-size: 15px;
                                font-weight: 600;
                                color: $black;
                                line-height: 1;
                            }
                        }

                        .field {
                            width: 100%;
                            margin-top: 13px;

                            .iput {
                                outline: none;
                                border: none;
                                background: transparent;
                                width: 100%;
                                border-bottom: 1px solid #ddd;
                                font-size: 14px;
                                color: $black;

                                &.area {
                                    resize: none;
                                    height: 80px;
                                    border: 1px solid #ddd;
                                    padding: 12px;
                                    border-radius: 3px;
                                    text-align: start;
                                    margin-top: 8px;
                                }
                            }
                        }
                    }

                    .actions {
                        margin-top: 8px;

                        .action {
                            outline: none;
                            border: none;
                            background: transparent;
                            background: $themeColor;
                            color: #fff;
                            padding: 9px 14px;
                            font-size: 13px;
                            font-weight: 500;
                            border-radius: 3px;
                            min-width: 100px;

                            @include media('<=desktop') {
                                min-width: 80px;
                            }

                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.add-credit-dialog {
    width: 100%;
    min-height: 500px;
    border-radius: 12px;
    position: relative;

    @include media('<=480px') {
        width: 100%;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    .hdr {
        padding: 8px 14px 8px 20px;
        border-bottom: 1px solid #ddd;

        .title {
            flex: 1;
        }

        .cross-btn {
            height: 36px;
            width: 36px;
            min-width: 36px;
            border-radius: 50%;
            line-height: 1;
            padding-bottom: 4px;
            justify-content: center;

            &:hover {
                background: #f8f8f8;
            }
        }
    }

    .form {
        padding: 22px;

        .label-text {
            font-size: 14px;
            font-weight: 500;
            color: #121212;
        }

        .blk {
            .title {
                margin-bottom: 20px;
            }

            .iput {
                border: 1px solid #767676;
                padding: 9px 12px;
                width: 100%;
                border-radius: 5px;

                @include media('<=480px') {
                    padding: 8px 12px;
                }

                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                @include anim;

                &:focus {
                    border-color: $themeColor;
                }
            }
        }

        .credit-amounts {
            justify-content: space-between;
            width: 100%;
            margin-top: 14px;
            overflow: hidden;

            .item {
                border: 1px solid #767676;
                padding: 8px 12px;
                width: 97%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                text-align: center;
                overflow: hidden;
                font-size: 14px;
                font-weight: 400;
                color: #121212;
                @include anim;

                .sign {
                    margin-right: 5px;
                }

                .iput {
                    width: 100%;
                    text-align: center;
                }

                &.active {
                    border-color: $themeColor;
                    background: rgba(56, 152, 236, 0.15);
                    color: $themeColor;
                }
            }
        }

        .purchase-btn {
            padding: 11px;
            border-radius: 4px;
            background: $themeColor;
            width: 100%;
            margin-top: 18px;
        }
    }
}

// Referral page
.referral-page {
    min-height: 100vh;
    padding: 22px $horizontalPending;

    @include media('<=large') {
        padding: 22px 0px;
    }

    .hdr {
        margin-bottom: 30px;

        .left {
            .page-title {
                line-height: 1;

                @include media('<=tablet') {
                    line-height: 1.4;
                    font-weight: 20px;
                }
            }
        }

        .right {
            flex: 1;
            justify-content: flex-end;
        }
    }

    .badge-block {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 3px;

        .badge-itm {
            background: $themeColor;
            padding: 1px 12px;
            border-radius: 28px;

            &:not(:last-child) {
                margin-right: 8px;
            }

            .badge-btn {
                font-size: 18px;
                margin-left: 6px;
                margin-bottom: 2px;
            }
        }
    }

    .wrapper {
        @include media('<=tablet') {
            flex-direction: column;
        }

        .left-side {
            flex: 1;
            margin-right: 60px;

            @include media('<=tablet') {
                margin-right: 0px;
                margin-bottom: 25px;
            }

            .referral-users-blk {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px 0px;
                max-width: fit-content;

                @include media('<=tablet') {
                    max-width: 100%;
                    justify-content: center;
                }

                .users {
                    display: flex;
                    align-items: center;

                    .user {
                        @include bgImage;
                        height: 58px;
                        width: 58px;
                        min-width: 58px;
                        margin-right: -14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: rgb(175, 117, 117);
                        font-size: 20px;
                        color: white;

                        &:nth-child(odd) {
                            z-index: 3;
                        }

                        &.add-btn {
                            background: $black !important;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &:hover {
                                .btn-lbl {
                                    @include show;
                                }
                            }

                            .icon {
                                color: #fff;
                                font-size: 20px;
                                margin-left: 2px;
                            }

                            .btn-lbl {
                                position: absolute;
                                top: 62px;
                                font-size: 12px;
                                color: #fff;
                                background: $black;
                                padding: 3px 8px;
                                border-radius: 4px;
                                width: max-content;
                                @include hide;
                                @include anim;
                                z-index: 3;
                            }
                        }
                    }
                }

                .meta {
                    margin: 25px 0px;

                    .tag {
                        line-height: 1;
                        margin-bottom: 3px;
                    }

                    .txt {
                        line-height: 1;
                    }
                }
            }

            .actions {
                flex: 1;

                .action-btn {
                    outline: none;
                    background: transparent;
                    border: 1px solid $borderColor;
                    width: 100%;
                    padding: 9px 12px;
                    border-radius: 3px;
                    color: $lightBlack;
                    height: 43px;

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &.share-btn {
                        border-color: $themeColor;
                        background: $themeColor;
                        color: #fff;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    .txt {
                        width: 100%;
                    }
                }

                .copy-btn {
                    background: $themeColor;
                    padding: 6px 8px;
                    min-width: fit-content;
                    border-radius: 4px;

                    .copy-txt {
                        font-size: 12px;
                        color: #fff;
                        margin-right: 3px;
                    }

                    svg {
                        height: 18px;
                    }
                }
            }

            .note {
                margin-top: 30px;

                @include media('<=tablet') {
                    margin-top: 20px;
                }
            }
        }

        .right-side {
            flex: 1;

            .title {
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;

                .ico {
                    margin-right: 10px;
                    font-size: 24px;
                }
            }

            .goals {
                margin: 25px 0px;

                .tit {
                    font-size: 16px;
                    font-weight: 500;
                    color: $black;
                }

                .txt {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: $black;
                    margin-top: 8px;

                    .dot {
                        margin-right: 4px;
                        margin-top: 2px;
                    }
                }
            }

            .demographic {
                .tit {
                    font-size: 16px;
                    font-weight: 600;
                    color: $black;
                }

                .blk {
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;

                    .lbl {
                        font-size: 13px;
                        color: $black;
                        line-height: 1;
                    }

                    .items {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: 8px;

                        .itm {
                            font-size: 13px;
                            color: $black;
                            padding: 6px 12px;
                            min-width: 90px;
                            background: #f5f5f5;
                            border-radius: 2px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 10px;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Messenger Page
.messenger-page {
    overflow: hidden;
    min-height: 100vh;

    .wrap {
        width: 100%;
        background: #fff;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        @include media('>=phone', '<=425px') {
            overflow: auto;
        }

        .messenger-side-blk {
            flex: 1;
            border-right: 1px solid #ddd;
            max-width: 285px;

            ::-webkit-scrollbar {
                width: 6px;
            }

            @include media('<=desktop') {
                max-width: 275px;
            }

            @include media('<=tablet') {
                max-width: 100%;
                border-right: none;

                &.hidden {
                    display: none;
                }
            }

            .head {
                padding: 14px 12px 0px 12px;
                z-index: 9;

                .hdr {
                    .search-bar {
                        background: #f8f8f8;
                        padding: 8px 11px;
                        width: 100%;
                        max-width: 100%;
                        border-radius: 20px;

                        .icon {
                            margin-right: 7px;
                            background: #f8f8f8;
                        }

                        .iput {
                            border: none;
                            outline: none;
                            width: 100%;
                            background: transparent;
                        }
                    }

                    .notic {
                        width: 35px;
                        height: 35px;
                        min-width: 35px;
                        background: #f8f8f8;
                        border-radius: 50%;
                        margin-left: 10px;

                        &:hover {
                            background: $themeColor;
                            color: #fff;
                        }
                    }
                }

                .nav {
                    padding: 15px 0px 6px 3px;
                    justify-content: space-around;

                    @include media('>=phone', '<=425px') {
                        justify-content: space-between;

                        .lin {
                            margin-right: 0px !important;
                        }
                    }

                    .lin {
                        &:hover {
                            color: $themeColor;
                        }

                        &:not(:last-child) {
                            margin-right: 10px;
                        }

                        &:first-child {
                            color: $themeColor !important;

                            &:before {
                                content: '';
                                position: absolute;
                                bottom: -3px;
                                height: 4px;
                                width: 4px;
                                left: 35%;
                                border-radius: 20px;
                                background: $themeColor;
                            }
                        }
                    }
                }
            }

            .frd-list {
                padding: 0 13px;
                overflow-y: auto;

                .item {
                    padding: 13.5px 0;
                    cursor: pointer;

                    .lef {
                        flex: 1;

                        .pic {
                            height: 42px;
                            width: 42px;
                            min-width: 42px;
                            border-radius: 50%;
                            margin-right: 10px;

                            .img {
                                @include bgImage;
                            }
                        }

                        .txt {
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .center-blk {
            flex: 2;

            @include media('<=tablet') {
                display: none;

                &.display {
                    display: flex !important;
                }
            }
        }
    }
}

// Chat box Section
.user-chat-box-section {
    height: 100%;
    flex: 1;

    .icon-btn {
        height: 38px;
        width: 38px;
        min-width: 38px;
        background: #f8f8f8;
        border-radius: 50%;
        margin-left: 10px;
        color: #333;

        &:hover {
            background: $themeColor;
            color: #fff;
        }

        &.back {
            display: none;
        }

        @include media('<=tablet') {
            &.back {
                display: block;
            }
        }
    }

    .search-bar {
        width: 100%;
        max-width: 100%;
        border: 1px solid #fff;
        border: 1px solid #e1e3e6;
        border-radius: 20px;
        padding: 8px 15px;
        background: #fff;

        &:focus-within {
            background: #fff;
        }

        .icon {
            margin-right: 7px;
        }

        .iput {
            border: none;
            outline: none;
            width: 100%;
            background: transparent;
        }
    }

    .block {
        flex: 2;

        ::-webkit-scrollbar {
            width: 6px;
        }

        .head {
            top: 0;
            left: 0;
            right: 0;
            min-height: 80px;
            padding: 14px 20px;
            border-bottom: 1px solid #ddd;
            background: #fff;
            border-radius: 0px 20px 0px 0px;
            z-index: 99;

            &.border {
                border-bottom: none;
            }

            @include media('>=phone', '<=425px') {
                padding: 14px;
            }

            .lef {
                flex: 1;

                .name {
                    margin-bottom: 4px;

                    @include media('>=phone', '<=425px') {
                        font-size: 18px;
                    }
                }

                .status {
                    padding-right: 8px;

                    @include media('>=phone', '<=425px') {
                        padding-right: 4px;
                    }
                }

                .btn {
                    padding: 0 8px;
                    border-left: 1px solid #bcbcbc;
                    cursor: pointer;
                    border-radius: 0px;
                    outline: none;

                    &:hover {
                        opacity: 0.8;
                    }

                    .ico {
                        margin-right: 5px;
                    }

                    @include media('>=phone', '<=425px') {
                        padding: 0px 5px;
                        border-left: 1.5px solid #bcbcbc;
                    }
                }
            }

            .rig {
                flex: 1;
                justify-content: flex-end;

                .min {
                    display: none;

                    @include media('>=phone', '<=425px') {
                        display: flex;
                    }
                }

                .full {
                    .lbl {
                        line-height: 1.6;
                        display: none;
                    }

                    @include media('>=phone', '<=425px') {
                        justify-content: space-around;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: -180px;
                        opacity: 0;
                        visibility: hidden;
                        height: 150px;
                        transition: all 0.3s cubic-bezier(1, -0.42, 0, 1.49) 0s;
                        background: #fff;
                        border-radius: 0px 0px 20px 20px;
                        z-index: 999;

                        &.display {
                            top: 0px;
                            opacity: 1;
                            visibility: visible;
                        }

                        .lbl {
                            display: block !important;
                        }

                        .icon-btn {
                            background: transparent;
                            margin-left: 0px;
                            font-size: 24px;
                        }
                    }
                }
            }

            .search {
                opacity: 0;
                visibility: hidden;
                width: 100%;
                height: 0;
                transform: translateY(-30px);

                &.show {
                    opacity: 1;
                    visibility: inherit;
                    height: 100%;
                    transform: translateY(0px);
                }

                .srch {
                    margin-top: 20px;
                    width: 100%;
                    border: 1px solid #ddd;
                    border-radius: 5px;

                    .search-bar {
                        padding: 0px 15px;
                        background: transparent !important;
                        border: none !important;

                        .iput {
                            font-size: 14px;
                        }
                    }

                    .action {
                        width: 250px;
                        border-left: 1px solid #ddd;
                        justify-content: center;
                        background: #fff;
                        padding: 7px 10px;
                        border-radius: 0px 5px 5px 0px;

                        .counter {
                            letter-spacing: 1px;
                        }

                        .btns {
                            padding: 0 16px;

                            .btn {
                                height: 22px;
                                width: 22px;
                                min-width: 22px;
                                border-radius: 50px;
                                background: #f8f8f8;
                                justify-content: center;

                                &:first-child {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .cancel {
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }

        .content {
            flex: 1;
            padding: 16px 20px;
            overflow-y: auto;

            @include media('>=phone', '<=425px') {
                padding: 16px 14px;
            }
        }

        .chatbox-ftr {
            bottom: 0;
            left: 0;
            right: 0;
            height: 80px;
            padding: 14px 20px;
            border-top: 1px solid #ddd;
            background: #fff;
            border-radius: 0px 0px 20px 0px;

            @include media('>=phone', '<=425px') {
                padding: 14px;
                height: 75px;
            }

            .left {
                flex: 2.35;

                .typing-area {
                    background: #f8f8f8;
                    padding: 10px 20px;
                    border-radius: 40px;
                    width: 100%;

                    .stickers {
                        margin-right: 10px;
                    }

                    .iput {
                        width: 100%;
                    }
                }

                .icon-btn {
                    background: $themeColor;
                    color: #fff;
                }
            }

            .actions {
                flex: 0.5;
                justify-content: flex-end;
            }
        }

        .cover {
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            display: none;

            @include media('>=phone', '<=425px') {
                display: block;
            }
        }
    }

    .chatbox-gallery {
        flex: 1;
        border-left: 1px solid #ddd;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        transform: translateX(270px);
        max-width: 0px;
        transition: all 0.3s ease-in-out 0s;

        ::-webkit-scrollbar {
            width: 6px;
        }

        @include media('<=desktop') {
            position: sticky;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 999;
        }

        @include media('>=phone', '<=425px') {
            position: fixed;
        }

        &.show {
            opacity: 1;
            visibility: inherit;
            transform: translateX(0px);
            max-width: 260px;
        }

        .hdr {
            padding: 15px 12px;

            .lef {
                flex: 1;

                .ico {
                    margin-right: 12px;
                }
            }
        }

        .wrap-data {
            padding: 3px 12px;
            overflow: auto;

            .connect {
                justify-content: center;
                margin: 15px 0px;

                .frd-pic {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;

                    .img {
                        @include bgImage;
                    }

                    .my-pic {
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        right: -15px;
                        bottom: -30px;
                        border: 3.5px solid #fff;

                        .img {
                            @include bgImage;
                        }
                    }
                }

                .names {
                    margin-top: 38px;
                }
            }

            .shared-media {
                .links {
                    padding: 5px 0px;
                    justify-content: space-between;

                    .lin {
                        &:not(:last-child) {
                            margin-right: 12px;
                        }

                        &:first-child {
                            color: $themeColor;

                            &:before {
                                content: '';
                                position: absolute;
                                bottom: -3px;
                                height: 4px;
                                width: 4px;
                                left: 35%;
                                border-radius: 20px;
                                background: $themeColor;
                            }
                        }

                        &:hover {
                            color: $themeColor;
                        }
                    }
                }

                .shared-result {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-column-gap: 2.5px;
                    grid-row-gap: 2.5px;
                    overflow: hidden;
                    border-radius: 12px;
                    margin: 10px 0px;

                    .item {
                        width: 100%;
                        height: 70px;
                        cursor: pointer;
                        overflow: hidden;

                        &:before {
                            @include imageBefore(0px);
                        }

                        &:hover {
                            &:before {
                                opacity: 1;
                            }

                            .img {
                                transform: scale(1.1);
                            }
                        }

                        .img {
                            @include bgImage(0px);
                        }
                    }
                }
            }

            .related-community {
                .lbl {
                    margin: 3px 0px 2px 0px;
                }

                .community-result {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .item {
                        width: 48px;
                        height: 48px;
                        margin: 4px;
                        cursor: pointer;
                        overflow: hidden;

                        .img {
                            @include bgImage;
                        }

                        @include media('>=phone', '<=425px') {
                            width: 45px;
                            height: 45px;
                        }

                        &:last-child {
                            background: $themeColor;
                            justify-content: center;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

// Messages
.message-blk {
    margin-bottom: 3px;

    &.frd {
        text-align: left;
        justify-content: flex-start;
        margin-right: 50%;

        .item {
            display: flex;
            align-items: center;
        }

        &:nth-child(2) {
            .dp {
                opacity: 0;
            }
        }
    }

    &.user {
        text-align: right;
        justify-content: flex-end;
        margin-left: 50%;
    }

    .item {
        width: fit-content;
    }

    .txt {
        background: #f8f8f8;
        padding: 8px 12px;
        border-radius: 0px 15px 15px 15px;
        text-align: start;

        &.user-txt {
            border-radius: 15px 15px 0px 15px;
            background: $themeColor;
            color: #fff;
        }
    }

    .dp {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-right: 8px;

        .img {
            @include bgImage;
        }
    }
}

// Empty Section
.empty-section-chat {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .image {
        height: 60px;
        filter: opacity(0.9);
    }

    .lbl {
        margin: 12px 0px 2px 0px;
    }

    .txt {
        line-height: 1.4;
    }
}

$propertiesSidebarWidth: 350px;

// Profile Setting page
.profile-page {
    background: #fff;
    min-height: 100vh;

    .wrapper {
        width: 100%;

        .left-side {
            padding: 20px 28px;
            width: 100%;

            .page-hdr {
                .page-title {
                    line-height: 1;
                    flex: 1;
                }

                .properties-btn {
                    @include media('>desktop') {
                        display: none;
                    }
                }
            }

            .personal-info {
                padding: 25px 0px;
                width: 750px;

                @include media('<=large') {
                    width: 100%;
                }

                .title {
                    margin-bottom: 25px;
                }

                .user-dp {
                    text-align: center;
                    max-width: fit-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include media('<=480px') {
                        max-width: 100%;
                    }

                    .img {
                        @include bgImage;
                        height: 132px;
                        width: 132px;
                    }

                    .img-btn {
                        font-size: 13px;
                        font-weight: 500;
                        color: $themeColor;
                        margin-top: 9px;
                        cursor: pointer;
                        @include anim;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .form {
                    margin-top: 30px;

                    .columns {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-column-gap: 35px;
                        grid-row-gap: 30px;

                        @include media('<=desktop') {
                            grid-column-gap: 25px;
                            grid-row-gap: 20px;
                        }

                        @include media('<=480px') {
                            grid-template-columns: repeat(1, 1fr);
                        }

                        .field {
                            .lbl {
                                font-size: 15px;
                                color: $lightBlack;
                                font-weight: 500;
                                margin-bottom: 5px;
                            }

                            .iput {
                                outline: none;
                                border: none;
                                background: #f8f8f8;
                                border: 1px solid #f8f8f8;
                                padding: 11px 18px;
                                border-radius: 4px;
                                font-weight: 500;
                                font-size: 15px;
                                color: $lightBlack;
                                text-align: start;
                                @include anim;
                            }

                            &.holder {
                                height: 46px;
                                width: 100%;
                            }
                        }

                        &.edit-mode {
                            .field {
                                .iput {
                                    background: transparent;
                                    border-color: $lightBlack;

                                    &:focus {
                                        border-color: $themeColor;
                                    }
                                }
                            }
                        }
                    }

                    .ftr {
                        .form-btn {
                            font-size: 15px;
                            font-weight: 500;
                            color: #fff;
                            border: 1px solid $themeColor;
                            background: $themeColor;
                            padding: 9px;
                            margin-top: 30px;
                            border-radius: 3px;
                            width: 120px;
                            @include anim;

                            &:not(:last-child) {
                                margin-right: 20px;
                            }

                            @include media('<=480px') {
                                flex: 1;

                                &:not(:last-child) {
                                    margin-right: 15px;
                                }
                            }

                            &.cancel {
                                background: transparent;
                                border-color: $themeColor;
                                color: $themeColor;
                            }
                        }
                    }
                }
            }
        }

        .right-side {
            min-width: $propertiesSidebarWidth;

            @include media('<=desktop') {
                min-width: 0px;
                position: fixed;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background: rgba(0, 0, 0, 0.4);
                @include anim;

                &.open {
                    @include show;
                }

                &.closed {
                    @include hide;
                }
            }

            .properties-block {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                width: $propertiesSidebarWidth;
                border-left: 1px solid $borderColor;
                background: #fff;

                @include media('<=desktop') {
                    top: 58px;
                    @include anim;

                    &.open {
                        right: 0;
                        @include show;
                    }

                    &.closed {
                        right: -350px;
                        @include hide;
                    }
                }

                @include media('<=phoneH') {
                    width: 100%;
                }

                .hdr {
                    border-bottom: 1px solid $borderColor;
                    height: 55px;
                    padding: 10px 15px;

                    @include media('<=phoneH') {
                        padding: 10px 21px;
                    }

                    .title {
                        font-size: 18px;
                        font-weight: 700;
                        color: $black;
                        flex: 1;
                    }

                    .total {
                        font-size: 14px;
                        font-weight: 500;
                        color: $black;
                    }

                    .close-btn {
                        height: 33px;
                        width: 33px;
                        min-width: 33px;
                        background: #f7f7f7;
                        border-radius: 50%;
                        line-height: 1.2;
                        margin-bottom: 2px;
                        margin-left: 12px;

                        @include media('>desktop') {
                            display: none;
                        }
                    }
                }

                .properties-list {
                    padding: 11px 14px 68px 14px;
                    overflow-y: auto;
                    height: 100%;

                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    .item {
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        .number {
                            margin-right: 12px;

                            &.holder {
                                height: 16px;
                                width: 30%;
                                border-radius: 50px;
                                margin-bottom: 5px;
                            }
                        }

                        .name {
                            &.holder {
                                height: 16px;
                                width: 90%;
                                border-radius: 50px;
                                margin-bottom: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Referral components
.referral-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .referrals-container {
        max-height: calc(100vh - 80px);
        width: 100%;
        margin: 0 auto;
        background: #fff;
        border: 1px solid #ddd;

        @include media('<=tablet') {
            max-height: 100%;
            height: 100%;
            width: 100%;
        }

        .hdr {
            padding: 15px 18px;
            border-bottom: 1px solid #ddd;

            .title {
                font-size: 16px;
                font-weight: 600;
                color: $black;
                margin-bottom: 3px;
            }

            .txt {
                font-size: 13px;
                color: $lightBlack;
                line-height: 1.4;
                font-weight: 400;
            }
        }

        .latitude-blk {
            .label {
                padding: 10px;
                font-size: 14px;
                color: $black;
                font-weight: 500;
                text-align: center;
            }

            .tabs-bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                background: #f2f3f5;
                padding: 8px 20px;
                height: 40px;

                .tab {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 3px 2px 3px 12px;
                    border-radius: 30px;
                    background: transparent;

                    &.active {
                        background: $themeColor;

                        .lbl,
                        .ico {
                            color: #fff;
                        }
                    }

                    .lbl {
                        font-size: 14px;
                        font-weight: 500;
                        color: $black;
                        line-height: 1;
                    }

                    .ico {
                        font-size: 20px;
                        color: $black;
                        line-height: 1;
                    }
                }

                .cross-btn {
                    font-size: 22px;
                    margin-right: -8px;
                    font-weight: 300;
                    color: $black;
                    cursor: pointer;
                }
            }
        }

        .filters-block {
            background: #fff;
            box-shadow: 0px 7px 8px rgba(24, 36, 60, 0.07);
            width: 100%;
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            padding: 14px;
            z-index: 5;
            @include dropDownAnim($duration: 0.15s);

            .filters-wrap {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;

                .filter {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .checkbox {
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        border-radius: 4px;
                        border: 1px solid $lightBlack;
                        margin-right: 12px;
                        position: relative;
                        transition: all 0.1s ease-in-out;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 2px;
                            top: 2px;
                            height: 14px;
                            width: 14px;
                            min-width: 14px;
                            border-radius: 3px;
                            background: transparent;
                            transition: all 0.1s ease-in-out;
                        }

                        &.active {
                            border-color: $themeColor;

                            &::before {
                                background: $themeColor;
                            }
                        }
                    }

                    .lbl {
                        font-size: 14px;
                        font-weight: 600;
                        color: $lightBlack;
                    }
                }
            }

            .filter-actions {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                margin-top: 15px;

                .action-btn {
                    outline: none;
                    border: none;
                    background: none;
                    font-size: 13px;
                    font-weight: 600;
                    color: $lightBlack;
                    padding: 7px 14px;
                    min-width: 110px;
                    background: none;
                    border: 1px solid transparent;
                    border-radius: 2px;
                    @include anim;

                    &:not(:last-child) {
                        margin-right: 5px;
                    }

                    &.filled {
                        background: #f5f5f5;
                        border: 1px solid $border-color-1;

                        &:hover {
                            background: $themeColor;
                            border-color: $themeColor;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .users {

            overflow: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }

            .user-blk {
                padding: 8px 18px;
                margin-bottom: 6px;

                &:last-child {
                    margin-bottom: 20px;
                }

                .user {
                    display: flex;
                    align-items: center;

                    .img {
                        @include bgImage;
                        height: 70px;
                        width: 70px;
                        min-width: 70px;
                    }

                    .meta {
                        padding: 0px 12px;

                        .name {
                            font-size: 15px;
                            font-weight: 600;
                            color: $black;
                            margin-bottom: 2px;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .values {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;

                            .txt {
                                font-size: 11px;
                                font-weight: 500;
                                color: $lightBlack;
                                line-height: 1;
                                margin: 2px;

                                .dot {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }

                .field-row {
                    width: 100%;
                    margin-top: 15px;
                    display: flex;
                    align-items: center;

                    .field {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .lbl {
                            font-size: 13px;
                            margin-bottom: 5px;
                            color: $black;
                        }

                        .iput {
                            width: 100%;
                            padding: 7px 12px;
                            border: 1px solid $lightBlack;
                            height: 35px;
                            font-size: 14px;
                            border-radius: 3px;

                            &.area {
                                overflow: hidden;
                            }
                        }
                    }

                    .send-btn {
                        font-size: 13px;
                        font-weight: 600;
                        margin-left: 10px;
                        height: 35px;
                        padding: 5px 16px;
                        border-radius: 3px;
                        background: $themeColor;
                        border: 1px solid $themeColor;
                        color: #fff;
                        @include anim;

                        &:disabled {
                            color: $black;
                            background: #f5f5f5;
                            border-color: $border-color-1;
                            cursor: no-drop;
                        }
                    }
                }
            }
        }

        .empty-user-list {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .txt {
                font-size: 15px;
                color: $lightBlack;
                font-weight: 600;
            }
        }

        .agent-detail {
            padding: 0px 18px;

            .back-btn {
                font-size: 25px;
                font-weight: 300;
                color: $black;
                cursor: pointer;
            }

            .user {
                display: flex;
                align-items: center;
                padding: 11px 0px;

                .img {
                    @include bgImage;
                    height: 75px;
                    width: 75px;
                    min-width: 75px;
                }

                .meta {
                    padding: 0px 12px;

                    .name {
                        font-size: 15px;
                        font-weight: 600;
                        color: $black;
                        margin-bottom: 2px;
                    }

                    .values {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        .txt {
                            font-size: 11px;
                            font-weight: 500;
                            color: $lightBlack;
                            line-height: 1;
                            margin: 2px;

                            .dot {
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }

            .about {
                font-size: 13px;
                color: $black;
                margin-bottom: 16px;
                line-height: 1.4;
            }

            .form {
                .field-row {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 12px;

                    .field {
                        flex: 1;

                        &:not(:last-child) {
                            margin-right: 12px;
                        }

                        .iput {
                            width: 100%;
                            outline: none;
                            border: 1px solid #f5f5f5;
                            background: #f5f5f5;
                            font-size: 14px;
                            color: $black;
                            padding: 9px 12px;
                            @include anim(0.15s);

                            &:focus {
                                border: 1px solid $lightBlack;
                                background: transparent;
                            }
                        }
                    }

                    .connect-btn {
                        font-size: 14px;
                        font-weight: 300;
                        color: #fff;
                        background: $themeColor;
                        width: 100%;
                        text-transform: uppercase;
                        padding: 9px 12px;
                    }
                }
            }

            .reviews {
                margin-bottom: 10px;

                .lbl {
                    margin-bottom: 5px;
                    font-weight: 500;
                }
            }

            .user-badges {
                margin-bottom: 14px;

                .lbl {
                    margin-bottom: 5px;
                    font-weight: 500;
                }

                .badges-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .itm {
                        background: #f5f5f5;
                        margin: 5px;
                        color: $black;
                        font-size: 13px;
                        padding: 8px 12px;
                        border-radius: 22px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
}

.referral-login-dialog {
    .hdr {
        height: 55px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color-2;
        padding: 12px 21px;

        .title {
            flex: 1;
            font-size: 15px;
            font-weight: 600;
            color: $black;
        }

        .cross-btn {
            font-size: 24px;
            color: $black;
            font-weight: 300;
            cursor: pointer;
        }
    }

    .content {
        padding: 12px 22px;

        .field {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .lbl {
                font-size: 14px;
                color: $black;
                margin-bottom: 4px;
            }

            .iput {
                outline: none;
                border: none;
                width: 100%;
                border: 1px solid $border-color-2;
                padding: 8px 12px;
                border-radius: 2px;
                font-size: 14px;
                @include anim;

                &:focus {
                    border-color: $themeColor;
                }
            }
        }

        .actions {
            margin-top: 45px;
            margin-bottom: 14px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .action {
                min-width: 100px;
                outline: none;
                font-size: 14px;
                color: #fff;
                background: $themeColor;
                padding: 9px;
                border-radius: 2px;

                &.transparent {
                    background: transparent;
                    color: $black;
                }
            }
        }
    }
}

// Edit Employee Dialog
.edit-user-dialog {
    .hdr {
        padding: 9px 20px;
        border-bottom: 1px solid $border-color-2;
        height: 56px;

        .left {
            flex: 1;
            display: flex;
            align-items: center;

            .title {
                font-size: 16px;
                font-weight: 600;

                span {
                    font-weight: 300;
                    color: $lightBlack;
                    font-size: 14px;
                }
            }
        }

        .right {
            .cross-btn {
                font-size: 26px;
                font-weight: 300;
                color: $black;
                cursor: pointer;
            }
        }
    }

    .user-content {
        padding: 13px 20px 25px 20px;

        .summary-blk {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-around;
            margin-bottom: 16px;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: $lightBlack;
                }

                .txt {
                    font-size: 13px;
                    color: $grey-1;
                }
            }
        }

        .user-blk {
            display: flex;
            margin-bottom: 15px;

            .image-blk {
                display: flex;
                flex-direction: column;
                width: 80px;
                min-width: 80px;
                margin-right: 20px;

                .dp {
                    @include bgImage(3px);
                    overflow: hidden;
                    margin-bottom: 6px;
                    height: 72px;
                }
            }

            .meta {
                width: 100%;
            }
        }

        .field-row {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .filed {
                width: 100%;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-right: 20px;
                }

                .iput {
                    width: 100%;
                    outline: none;
                    background: none;
                    border-bottom: 1px solid $border-color-2;
                    font-size: 13px;
                    color: $black;
                }
            }
        }

        .check-referral {
            cursor: pointer;

            .checkbox-btn {
                height: 21px;
                width: 21px;
                min-width: 21px;
                border: 1px solid $border-color-2;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include anim;
                margin-right: 10px;
                cursor: pointer;
                position: relative;

                &:hover {
                    border-color: $themeColor;
                }

                .icon {
                    font-size: 14px;
                    visibility: hidden;
                    opacity: 0;
                }

                &.on {
                    background: $themeColor;
                    border-color: $themeColor;

                    .icon {
                        visibility: visible;
                        opacity: 1;
                        color: #fff;
                    }
                }
            }

            .checkbox-lbl {
                font-size: 13px;
                font-weight: 600;
                color: $black;
            }
        }

        .tag-blk {
            display: flex;
            flex-direction: column;

            .tit {
                font-size: 15px;
                font-weight: 600;
                color: $black;
                margin-bottom: 5px;
            }

            .selected-tags {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-left: -5px;

                .tag {
                    font-size: 13px;
                    color: $black;
                    background: #f5f5f5;
                    padding: 6px 12px;
                    border-radius: 20px;
                    margin: 4px;
                }
            }

            .tag-selector {
                margin-top: 11px;
                position: relative;

                .field {
                    display: flex;
                    align-items: center;
                    border: 1px solid $border-color-2;
                    width: 100%;
                    padding: 8px 15px;
                    border-radius: 4px;

                    .iput {
                        width: 100%;
                        font-size: 14px;
                        color: $black;
                    }

                    .add-btn {
                        font-size: 14px;
                        color: $black;
                        cursor: pointer;
                    }
                }
            }
        }

        .tag-list {
            background: #ffffff;
            box-shadow: 0px 8px 9px rgba(0, 0, 0, 0.12);
            width: 100%;
            display: flex;
            flex-direction: column;
            position: sticky;
            left: 0px;
            right: 0px;
            padding: 5px 0px;
            display: none;

            &.active {
                display: flex;
                @include dropDownAnim(0.2s);
            }

            .item {
                font-size: 14px;
                color: $black;
                padding: 6px 20px;
                width: 100%;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    background: #f8f8f8;
                }
            }
        }

        .review-summary-blk {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .item {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                .lbl {
                    font-size: 14px;
                    color: $black;
                    font-weight: 600;
                    margin-right: 8px;
                }

                .val {
                    font-size: 14px;
                    color: $black;
                }
            }
        }

        .actions {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            .action-btn {
                padding: 9px 14px;
                font-size: 14px;
                font-weight: 500;
                color: #fff;
                background: $themeColor;
                min-width: 110px;
                text-align: center;
                border-radius: 3px;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 7px;
                }

                &.transparent {
                    background: transparent;
                    color: $grey-1;
                }
            }
        }
    }
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-center-vt {
    display: flex;
    align-items: center;
}

.flex-gap-20px {
    gap: 20px;
}

.videos-page {
    .sumary-row-item {
        height: 180px;
        width: 180px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 22px;
        font-weight: 600;

        .video-blk {
            @include bgImage(0px);
        }
    }
}

// Booking Scheduler
.booking-scheduler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .scheduler-container {
        max-height: calc(100vh - 80px);

        margin: 0 auto;
        background: #fff;
        border: 1px solid $border-color-1;
        overflow: hidden;

        @include media('<=tablet') {
            max-height: 100%;
            height: 100%;
            width: 100%;
        }

        .container-hdr {
            display: flex;
            align-items: center;
            height: 58px;
            padding: 7px 15px;
            background: #fff;
            border-bottom: 1px solid $border-color-1;
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            z-index: 9;

            .left {
                flex: 1;

                .back-btn {
                    font-size: 14px;
                    font-weight: 500;
                    color: $themeColor;

                    &:disabled {
                        opacity: 0.5;
                        cursor: no-drop;
                    }
                }
            }

            .right {
                .cross-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 33px;
                    width: 33px;
                    min-width: 33px;
                    background: rgba(21, 27, 83, 0.06);
                    border-radius: 100px;
                    font-size: 28px;
                    color: $black;
                    font-weight: 300;
                    line-height: 1;
                    padding-top: 2px;
                    @include anim(0.2s);

                    &:hover {
                        background: rgba(21, 27, 83, 0.08);
                    }
                }
            }
        }

        .scheduler-content {
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            .meta {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .title {
                    font-size: 20px;
                    font-weight: 600;
                    color: $black;
                }

                .txt {
                    font-size: 15px;
                    font-weight: 300;
                    color: $lightBlack;
                }

                .stamp {
                    margin-top: 8px;

                    .icon {
                        font-size: 16px;
                        margin-right: 7px;
                    }
                }
            }

            .content-ftr {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 12px 0px 22px 0px;

                .ftr-img {
                    height: 18px;
                }
            }

            .contact-form {
                width: 100%;
                margin-top: 14px;

                .field {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;

                    .lbl {
                        font-size: 13px;
                        font-weight: 600px;
                        color: $black;
                        margin-bottom: 4px;
                    }

                    .iput {
                        font-size: 14px;
                        color: $black;
                        border: 1px solid $border-color-1;
                        padding: 9px 15px;
                        @include anim(0.2s);

                        &:focus {
                            border-color: $themeColor;
                        }

                        &.area {
                            resize: none;
                            height: 80px;
                        }
                    }
                }

                .send-msg-btn {
                    width: 100%;
                    font-size: 13px;
                    color: #fff;
                    padding: 10px 20px;
                    background: $themeColor;
                    color: #fff;
                    border-radius: 3px;
                    text-align: center;
                    margin-top: 8px;
                }
            }

            .step-1 {
                padding: 11px 15px;

                .block {
                    margin-top: 16px;

                    .label {}

                    .options {
                        flex-wrap: wrap;

                        .item {
                            background: rgba(21, 27, 83, 0.04);
                            border-radius: 2px;
                            padding: 7px 10px;
                            min-width: 80px;
                            font-size: 13px;
                            text-align: center;
                            color: $black;
                            margin: 4px;
                            @include anim(0.2s);
                            cursor: pointer;

                            &:hover {
                                background: rgba(21, 27, 83, 0.08);
                            }

                            &.active {
                                background: $themeColor;
                                border-color: $themeColor;
                                color: #fff;
                            }
                        }
                    }

                    .calender {
                        width: 100%;

                        .react-calendar {
                            border: none;
                            width: 100%;
                        }
                    }
                }
            }

            .step-2 {
                padding: 11px 15px;

                .meta {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .title {
                        font-size: 18px;
                        font-weight: 600;
                        color: $black;
                        line-height: 1.4;
                    }

                    .txt {
                        font-size: 14px;
                        font-weight: 300;
                        color: $lightBlack;
                    }

                    .txt-2 {
                        font-weight: 400;
                    }
                }

                .plans-list {
                    margin-top: 14px;

                    .plane-card {
                        padding: 10px 0px;
                        cursor: pointer;

                        &:not(:last-child) {
                            border-bottom: 1px solid $borderColor;
                        }

                        .graphic {
                            height: 72px;
                            width: 72px;
                            min-width: 72px;
                            margin-right: 22px;

                            .img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                        }

                        .content {
                            .nam {}

                            .txt {
                                font-size: 12px;
                                color: $black;
                            }
                        }
                    }
                }
            }

            .step-3 {
                .graphic {
                    @include bgImage(0px);
                    height: 180px;
                }

                .actions {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    padding: 8px 15px;

                    .action-btn {
                        font-size: 14px;
                        font-weight: 500;
                        color: $themeColor;
                    }
                }

                .content {
                    padding: 5px 15px;

                    .name {
                        font-size: 18px;
                        font-weight: 600;
                        color: $black;
                    }

                    .txt {
                        color: $lightBlack;
                        font-size: 13px;
                        margin-bottom: 3px;
                    }

                    .act-btns {
                        margin-top: 10px;

                        .act-btn {
                            width: 100%;
                            padding: 9px 20px;
                            text-align: center;
                            font-size: 14px;
                            text-align: center;
                            border: 1px solid $themeColor;
                            background: $themeColor;
                            color: #fff;
                            cursor: pointer;

                            &:not(:last-child) {
                                margin-bottom: 10px;
                            }

                            &.transparent {
                                background: transparent;
                                color: $themeColor;
                            }
                        }
                    }

                    .take-tour {
                        font-size: 14px;
                        font-weight: 500;
                        color: $themeColor;
                        text-decoration: underline;
                        margin-top: 22px;
                        margin-bottom: 5px;
                        cursor: pointer;
                    }

                    .apply-btn {
                        font-size: 13px;
                        font-weight: 500;
                        color: $themeColor;
                        text-decoration: underline;
                        max-width: fit-content;
                        margin-top: 10px;
                    }

                    .stamp {
                        font-size: 13px;
                        font-weight: 300;
                        color: $grey-1;
                        margin-top: 4px;
                        margin-bottom: 20px;
                    }
                }
            }

            .step-4 {
                padding: 11px 15px;
            }

            .step-5 {
                padding: 11px 15px;

                .actions {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 14px;

                    .action-btn {
                        padding: 4px 10px;
                        border: 1px solid $lightBlack;
                        font-size: 13px;
                        color: $black;
                        font-weight: 300;
                        border-radius: 3px;
                        @include anim(0.2s);

                        &:not(:last-child) {
                            margin-right: 20px;
                        }

                        &.active {
                            border-color: $themeColor;
                            color: $themeColor;
                        }
                    }
                }

                .calender {
                    width: 100%;
                    margin-top: 22px;

                    .react-calendar {
                        border: none;
                        width: 100%;
                    }
                }
            }

            .step-6 {
                padding: 11px 15px;

                .time-table {
                    width: 100%;

                    .tit {
                        margin: 14px 0px;
                    }

                    .time-list {
                        width: 100%;
                        display: grid;
                        grid-gap: 10px;
                        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

                        .time {
                            font-size: 13px;
                            color: $black;
                            padding: 9px 12px;
                            font-weight: 300;
                            background: rgba(21, 27, 83, 0.04);
                            cursor: pointer;
                            @include anim(0.2s);
                            text-align: center;
                            width: 100%;

                            &:hover {
                                background: rgba(21, 27, 83, 0.08);
                            }
                        }
                    }
                }
            }
        }
    }
}